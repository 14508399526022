/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
// import {KTSVG} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Solicitudes</span>
        </div>
      </div>
      <SidebarMenuItem
        to='request/credit/personal'
        icon='/media/icons/duotune/graphs/gra001.svg'
        title='Listado'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='request/credit/approved'
        icon='/media/icons/duotune/graphs/gra004.svg'
        title='Aprobadas'
        fontIcon='bi-layers'
      />
      {/* <SidebarMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </SidebarMenuItemWithSub> */}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Configuraciones</span>
        </div>
      </div>

      <SidebarMenuItem
        to='settings/credit-level-manager'
        icon='/media/icons/duotune/abstract/abs025.svg'
        title='Nivel Crédito'
        fontIcon='bi bi-list-ol'
      />

      <SidebarMenuItem
        to='settings/request-reasons'
        icon='/media/icons/duotune/abstract/abs025.svg'
        title='Mant. Razones'
        fontIcon='bi bi-list-ol'
      />
      <SidebarMenuItemWithSub
        to='/security'
        title='Seguridad'
        fontIcon='bi-shield-check'
        icon='/media/icons/duotune/general/gen051.svg'
      >
        <SidebarMenuItem to='security/users' title='Usuarios' hasBullet={true} />
        <SidebarMenuItem to='security/roles' title='Roles' hasBullet={true} />
      </SidebarMenuItemWithSub>
    </>
  )
}

export {SidebarMenuMain}
