/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

var defaultThemeMode: string | null = localStorage.getItem('kt_theme_mode_value')

const AuthLayout = () => {
  useEffect(() => {
    defaultThemeMode = localStorage.getItem('kt_theme_mode_value')

    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
      {/* begin::Body */}
      <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1'>
        {/* begin::Form */}
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          <div className='w-lg-500px p-10'>
            <Outlet />
          </div>
        </div>
      </div>

      {defaultThemeMode === 'light' && (
        <div
          className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2'
          style={{backgroundImage: `url(${toAbsoluteUrl('/media/misc/auth-bg.jpg')})`}}
        >
          <div className='d-flex flex-column flex-center py-15 px-5 px-md-15 w-100'>
            <Link to='/' className='mb-12'>
              <img alt='Logo' src={toAbsoluteUrl('/media/logos/sigci1.png')} className='h-200px' />
            </Link>
          </div>
        </div>
      )}

      {defaultThemeMode === 'dark' && (
        <div
          className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2'
          style={{backgroundImage: `url(${toAbsoluteUrl('/media/misc/background-dark.jpg')})`}}
        >
          <div className='d-flex flex-column flex-center py-15 px-5 px-md-15 w-100'>
            <Link to='/' className='mb-12'>
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/sigciWhite.png')}
                className='h-200px'
              />
            </Link>
          </div>
        </div>
      )}
      {/* {defaultThemeMode === 'light' ? (
        <div
          className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2'
          style={{backgroundImage: `url(${toAbsoluteUrl('/media/misc/auth-bg.jpg')})`}}
        >
          <div className='d-flex flex-column flex-center py-15 px-5 px-md-15 w-100'>
            <Link to='/' className='mb-12'>
              <img alt='Logo' src={toAbsoluteUrl('/media/logos/sigci1.png')} className='h-200px' />
            </Link>
          </div>
        </div>
      ) : (
        <div
          className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2'
          style={{backgroundImage: `url(${toAbsoluteUrl('/media/misc/background-dark.jpg')})`}}
        >
          <div className='d-flex flex-column flex-center py-15 px-5 px-md-15 w-100'>
            <Link to='/' className='mb-12'>
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/sigciWhite.png')}
                className='h-200px'
              />
            </Link>
          </div>
        </div>
      )} */}
    </div>
  )
}

export {AuthLayout}
