/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import * as FileSaver from 'file-saver';
// import FileSaver from "file-saver";
import XLSX from 'sheetjs-style';
import Tooltip from '@mui/material/Tooltip'
import { KTSVG } from '../../../_metronic/helpers'

let initValues = {
    names: '',
    lastNames: '',
    identificationType: '1',
    identificationNo: '',
    phone_number: '',
    telephone: '',
    email: '',
    contactPerson: '',
    totalEarning: '',
    requestedAmount: '',
    reasonRequest: 0,
    familiarAmount: false,
    occupancy: '',
    comments: '',
    statusCode: '',
    requestType: '',
    score: 0
}

type Props = {
    values: any[],
}

export default function ExportApprovedRequest({ values }: Props) {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    async function exporToExcel() {
        const ws = XLSX.utils.json_to_sheet(values);
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, "solicitudes-aprobadas" + fileExtension)
    }

    return (
        <>
            <Tooltip title="Exportar a Excel">
                <button type='button' className='btn btn-light-primary me-3' onClick={() => exporToExcel()}>
                    <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
                    Export
                </button>
            </Tooltip>
        </>
    )
}