import React from 'react'
import {PermissionModel} from './_models'

const PERMISSION_LOCAL_STORAGE_KEY = 'app_user_permission'

function CheckInputPermission(page: string) {
  var localValues: PermissionModel[] = JSON.parse(
    localStorage.getItem(PERMISSION_LOCAL_STORAGE_KEY)!
  )
  return localValues.find((data) => data.pageName === page)
}

export default CheckInputPermission
