/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useContext } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'

import { useFormik } from 'formik'
import { login } from '../core/_requests'
import { GET_ROLE_PERMISSION_BYROLE } from '../../../core/_request'
import { useAuth } from '../core/Auth'
import { ChangePasswordModal } from '../../../../_metronic/partials'
import { UserModel } from '../../../../app/modules/auth/core/_models'
import jwt_decode from 'jwt-decode'
import Swal from 'sweetalert2'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Formato del correo es invalido')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Correo electrónico es obligatorio'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Contraseña es obligatoria'),
})

const initialValues = {
  email: '',
  password: '',
}

export function Login() {
  const [loading, setLoading] = useState(false)
  const { saveAuth, setCurrentUser } = useAuth()
  const [newPasswordModal, setNewPasswordModal] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const currentUser: UserModel = {
    id: 'string',
    username: 'string',
    password: 'string',
    email: 'string',
    first_name: 'string',
    last_name: 'string',
  }
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      setLoading(true)

      try {
        console.log("values", values);
        
        const { data: auth } = await login(values.email, values.password)
        if (auth?.isSuccess) {
          var decodedToken: any = jwt_decode(auth.data)

          const authObject = {
            id: decodedToken.Id,
            first_name: decodedToken.first_name,
            last_name: decodedToken.last_name,
            api_token: auth.data,
            created_at: '',
            email_verified_at: '',
            updated_at: '',
          }

          currentUser.id = decodedToken.Id
          currentUser.username = decodedToken.sub
          currentUser.password = undefined
          currentUser.email = decodedToken.email
          currentUser.first_name = decodedToken.first_name
          currentUser.last_name = decodedToken.last_name

          
          saveAuth(authObject)
          setCurrentUser(currentUser)

          if (decodedToken.email_confirm === 'False') {
            setUserEmail(decodedToken.email)
            setLoading(false)
            Swal.fire({
              title: 'Información!',
              text: 'Como nuevo usuario, es necesario que actualice su contraseña',
              icon: 'info',
            })
            setNewPasswordModal(true)
            resetForm()
          } else {
            GET_ROLE_PERMISSION_BYROLE_FUNC(Number(decodedToken.IdRole))
          }
        } else {
          setSubmitting(false)
          setLoading(false)
          Swal.fire({
            title: 'Información!',
            text: 'La información ingresada es incorrecta. Favor verificar',
            icon: 'info',
          })
        }
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('Las credenciales ingresadas son incorrectas. Favor verificar')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  async function GET_ROLE_PERMISSION_BYROLE_FUNC(roleId: number) {
    setLoading(true)
    const { data: result } = await GET_ROLE_PERMISSION_BYROLE(roleId)
    if (result?.isSuccess) {
      localStorage.setItem('app_user_permission', JSON.stringify(result.data))
    }
    setLoading(false)
  }

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Iniciar Sesión</h1>
        {/* <div className='text-gray-500 fw-semibold fs-6'>Your Social Campaigns</div> */}
      </div>
      {/* begin::Heading */}

      {/* begin::Login options */}
      {/* <div className='row g-3 mb-9'>
        begin::Col
        <div className='col-md-6'>
          begin::Google link
          <a
            href='#'
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
              className='h-15px me-3'
            />
            Sign in with Google
          </a>
          end::Google link
        </div>
        end::Col

        begin::Col
        <div className='col-md-6'>
          begin::Google link
          <a
            href='#'
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}
              className='theme-light-show h-15px me-3'
            />
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/apple-black-dark.svg')}
              className='theme-dark-show h-15px me-3'
            />
            Sign in with Apple
          </a>
          end::Google link
        </div>
        end::Col
      </div> */}
      {/* end::Login options */}

      {/* begin::Separator */}
      {/* <div className='separator separator-content my-14'>
        <span className='w-125px text-gray-500 fw-semibold fs-7'>Or with email</span>
      </div> */}
      {/* end::Separator */}

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        // <></>
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>
            Ejemplo: Correo Electrónico <strong>admin@demo.com</strong> y contraseña{' '}
            <strong>demo</strong>
            para continuar
          </div>
        </div>
      )}

      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>Correo Electrónico</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>

      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Contraseña</label>
        <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Continuar</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Espere...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info text-center'>
            <strong>V 1.0</strong>
          </div>
        </div>
      </div>
      <ChangePasswordModal
        show={newPasswordModal}
        emailProp={userEmail}
        handleClose={() => setNewPasswordModal(false)}
      />
    </form>
  )
}
