import React, {useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
// ---COMPONENTS
import DetailHeader from '../../components/creditRequest/DetailHeader'
import {RoundedChart} from '../../components/RoundedChart'
import {Timeline} from '../../components/Timeline'
import FileList from '../../components/FileList'
import Swal from 'sweetalert2'
// --ENDPOINTS
import {
  GET_PERSONAL_REQUEST_BY_ID_REQUEST,
  UPDATE_CREATE_ACTIVITY_REQUEST,
  GET_CREDIT_ACTIVITIES_REQUEST,
  GET_REQUEST_CREDIT_LEVEL_REQUEST,
  UPDATE_REQUEST_SCORE
} from './core/_request'
// --PERMISSIONS
import CheckInputPermission from '../../core/CheckInputPermission'
import {PermissionModel} from '../../core/_models'

// ---- react components
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'

export default function CreditRequestDetail() {
  const location: any = useLocation()
  const [value, setValue] = React.useState('1')
  const [loading, setLoading] = useState(false)
  const [activities, setActivities] = useState<any[]>([])
  const [userPermission, setUserPermission] = useState<PermissionModel>()
  const [creditLevel, setCreditLevel] = useState("")
  const [requestDetail, setRequestDetail] = useState({
    names: '',
    lastNames: '',
    identificationType: '3',
    identificationNo: '',
    phone_number: '',
    telephone: '',
    email: '',
    contactPerson: '',
    totalEarning: '',
    requestedAmount: '',
    reasonRequest: 0,
    familiarAmount: false,
    occupancy: '',
    comments: '',
    statusCode: '',
    requestType: '',
    score : 0
  })

  useEffect(() => {
    setUserPermission(CheckInputPermission('Detalle de Crédito'))
    GET_PERSONAL_REQUEST_BY_ID_REQUEST_FUNCTION(location?.state.requestId)
  }, [location])

  useEffect(() => {
    GET_CREDIT_ACTIVITIES_REQUEST_FUNCTION(location?.state.requestId)
  }, [location])

  async function GET_PERSONAL_REQUEST_BY_ID_REQUEST_FUNCTION(id: number) {
    setLoading(true)
    const {data: result} = await GET_PERSONAL_REQUEST_BY_ID_REQUEST(id);
    GET_REQUEST_CREDIT_LEVEL_REQUEST_FUNCTION(Number(result?.data?.totalEarning))
    setRequestDetail(result.data)
    setLoading(false)
  }

  async function GET_CREDIT_ACTIVITIES_REQUEST_FUNCTION(id: number) {
    setLoading(true)
    const {data: result} = await GET_CREDIT_ACTIVITIES_REQUEST(id)
    setActivities(result.data)
    setLoading(false)
  }

  async function GET_REQUEST_CREDIT_LEVEL_REQUEST_FUNCTION(amount: number) {
    setLoading(true)
    const {data: result} = await GET_REQUEST_CREDIT_LEVEL_REQUEST(amount)
    setCreditLevel(result.data)
    setLoading(false)
  }

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  async function updateRequestFunction(value: string) {
    try {
      setLoading(true)
      requestDetail.statusCode = value
      const {data: result} = await UPDATE_CREATE_ACTIVITY_REQUEST(requestDetail)
      if (result?.isSuccess) {
        Swal.fire({
          title: 'Enhorabuena!',
          text: 'El estado de la solicitud ha sido actualizado exitosamente',
          icon: 'success',
        })
        setLoading(false)
        GET_PERSONAL_REQUEST_BY_ID_REQUEST_FUNCTION(location?.state.requestId)
      } else {
        Swal.fire({
          title: 'Información',
          text: 'Algo ha salido mal. Contactar soporte técnico',
          icon: 'info',
        })
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)

      Swal.fire({
        title: 'Información',
        text: 'Algo ha salido mal. Contactar soporte técnico',
        icon: 'info',
      })
    }
  }

  async function updateRequestScore(value: number) {
    try {
      setLoading(true)
      requestDetail.score = value
      const {data: result} = await UPDATE_REQUEST_SCORE(requestDetail)
      if (result?.isSuccess) {
        Swal.fire({
          title: 'Enhorabuena!',
          text: 'El score de la solicitud ha sido actualizado exitosamente',
          icon: 'success',
        })
        setLoading(false)
        GET_PERSONAL_REQUEST_BY_ID_REQUEST_FUNCTION(location?.state.requestId)
      } else {
        Swal.fire({
          title: 'Información',
          text: 'Algo ha salido mal. Contactar soporte técnico',
          icon: 'info',
        })
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)

      Swal.fire({
        title: 'Información',
        text: 'Algo ha salido mal. Contactar soporte técnico',
        icon: 'info',
      })
    }
  }
  

  return (
    <>
      <DetailHeader
        values={requestDetail}
        updateRequest={(value) => updateRequestFunction(value)}
        updateScore={(value) => updateRequestScore(value)}
        loading={loading}
        permissions={userPermission}
      />
      <Box sx={{width: '100%', typography: 'body1'}} className='card'>
        <TabContext value={value}>
          <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
            <TabList onChange={handleChange} aria-label='lab API tabs example'>
              <Tab
                className='text-muted fw-semibold me-1'
                label='Información de crédito'
                value='1'
              />
              <Tab className='text-muted fw-semibold me-1' label='Documentos' value='2' />
              <Tab className='text-muted fw-semibold me-1' label='Actividades' value='3' />
            </TabList>
          </Box>
          <TabPanel value='1'>
            <div className='row g-5 g-xl-8 mt-3 '>
              <div className='col-xl-12'>
                <RoundedChart
                  className='card-xl-stretch mb-xl-8'
                  chartColor='primary'
                  chartHeight='200px'
                  letter={creditLevel}
                  score={requestDetail.score}
                />
              </div>
            </div>
          </TabPanel>
          <TabPanel value='2'>
            <FileList
              requestId={location?.state.requestId}
              requestType={'P'}
              loading={loading}
              updateRequest={() =>
                GET_CREDIT_ACTIVITIES_REQUEST_FUNCTION(location?.state.requestId)
              }
              permissions={userPermission}
            />
          </TabPanel>
          <TabPanel value='3'>
            <Timeline className='mb-8 mb-xxl-12' activities={activities} />
          </TabPanel>
        </TabContext>
      </Box>
    </>
  )
}
