/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import moment from 'moment'
import {KTSVG} from '../../_metronic/helpers'
import {TypeOf} from 'yup'

interface ListActivities {
  comment: string
  createdAt: string
  createdBy: number
  id: number
  requestId: number
  type: string
}

type Props = {
  className: string
  activities: ListActivities[]
}

const Timeline: React.FC<Props> = ({className, activities}) => {
  return (
    <div className={`card ${className}`}>
      <div className='card-header align-items-center border-0 mt-4'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bold mb-2 text-dark'>Actividades</span>
          <span className='text-muted fw-semibold fs-7'>Logs de la solicitud</span>
        </h3>
      </div>

      {/* begin::Body */}
      <div className='card-body pt-5'>
        {/* begin::Timeline */}
        <div className='timeline-label'>
          {activities.map((data, index) => (
            <div className='timeline-item' key={index}>
              <div className='timeline-label fw-bold text-gray-800 fs-6'>
                {moment(data.createdAt).format('L')}
              </div>

              <div className='timeline-badge'>
                <i className='fa fa-genderless text-success fs-1'></i>
              </div>

              <div className='timeline-content d-flex'>
                <span className='fw-bold text-gray-800 ps-3'>{data.comment}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export {Timeline}
