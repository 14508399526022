/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import { KTSVG } from '../../../_metronic/helpers'
import { Link } from 'react-router-dom'
// ---- react components
import Avatar from '@mui/material/Avatar'
import { PermissionModel } from '../../core/_models'
import ChangeReqeustStatusModal from '../modal/ChangeReqeustStatusModal'
import Tooltip from '@mui/material/Tooltip'
import Swal from 'sweetalert2'

let initValues = {
  names: '',
  lastNames: '',
  identificationType: '1',
  identificationNo: '',
  phone_number: '',
  telephone: '',
  email: '',
  contactPerson: '',
  totalEarning: '',
  requestedAmount: '',
  reasonRequest: 0,
  familiarAmount: false,
  occupancy: '',
  comments: '',
  statusCode: '',
  requestType: '',
  score: 0
}

type Props = {
  values: typeof initValues
  updateRequest: (name: string) => void
  updateScore: (name: number) => void
  loading: boolean
  permissions: PermissionModel | undefined
}

export default function DetailHeader({ updateRequest, updateScore, values, loading, permissions }: Props) {
  const [modal, setModal] = React.useState(false)
  const [manualScore, setManualScore] = React.useState(0)

  useEffect(() => {
    setManualScore(values.score)
  }, [values])

  function handleOpenModal() {
    setModal(true);
  }
  function handleCloseModal() {
    setModal(false)
  }



  function CLASS_STATUS(currenStatus: string) {
    var color = ''
    switch (currenStatus) {
      case 'RQ001':
        color = 'badge fs-7 fw-semibold badge-light-warning'
        break
      case 'RQ002':
        color = 'badge fs-7 fw-semibold badge-light-primary'
        break
      case 'RQ003':
        color = 'badge fs-7 fw-semibold badge-light-success'
        break
      case 'RQ004':
        color = 'badge fs-7 fw-semibold badge-light-danger'
        break
      default:
        color = 'badge fs-7 fw-semibold badge-light-warning'
        break
    }
    return color
  }

  function CLASS_LABEL(currentStatus: string) {
    var label = ''
    switch (currentStatus) {
      case 'RQ001':
        label = 'PENDIENTE'
        break
      case 'RQ002':
        label = 'EN PROCESO'
        break
      case 'RQ003':
        label = 'APROBADO'
        break
      case 'RQ004':
        label = 'DECLINADO'
        break
      default:
        label = 'PENDIENTE'
        break
    }

    return label
  }

  function HandleScore(score: string) {
    var value =  parseInt(score);
    if (value > 850) {
      Swal.fire({
        title: 'Información',
        text: 'El score no puede ser mayor a 850',
        icon: 'info',
      })
      setManualScore(850)
     return; 
    }
    setManualScore(value)

    
  }


  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-9 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            <div className='me-7 mb-4'>
              <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                {/* <img src={toAbsoluteUrl('/media/avatars/300-1.jpg')} alt='profile' /> */}
                <Avatar sx={{ height: '70px', width: '70px', bgcolor: '#454eff' }}>
                  {values.names[0]}
                  {values.lastNames[0]}
                </Avatar>
                {/* <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div> */}
              </div>
            </div>

            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-2'>
                    <a className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                      {values.names} {values.lastNames}
                    </a>
                    {/* {values.statusCode === 'RQ003' && (
                      <a
                        className='btn btn-sm btn-light-success fw-bolder ms-2 fs-8 py-1 px-3'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_upgrade_plan'
                      >
                        Upgrade to Pro
                      </a>
                    )} */}
                    <div>
                      <a className='text-dark fw-bold text-hover-primary d-block fs-6'>
                        <span className={CLASS_STATUS(values.statusCode)}>
                          {CLASS_LABEL(values.statusCode)}
                        </span>
                      </a>
                    </div>
                  </div>

                  <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                    <a className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'>
                      <KTSVG
                        path='/media/icons/duotune/communication/com011.svg'
                        className='svg-icon-4 me-1'
                      />
                      {values.email}
                    </a>
                  </div>
                  <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                    <a className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'>
                      <KTSVG
                        path='/media/icons/duotune/communication/com005.svg'
                        className='svg-icon-4 me-1'
                      />
                      {values.phone_number}
                    </a>
                  </div>
                </div>

                {values.statusCode !== 'RQ003' && (
                  <div className='d-flex my-4'>
                  <a className='btn btn-sm btn-light me-2' id='kt_user_follow_button'>
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr012.svg'
                      className='svg-icon-3 d-none'
                    />

                    {permissions?.update && (
                      <span className='indicator-label' onClick={() => updateRequest('RQ004')}>
                        No Aplica
                      </span>
                    )}
                  </a>
                  {permissions?.update && (
                    <a
                      onClick={() => handleOpenModal()}
                      className='btn btn-sm btn-primary me-3'
                    >
                      Cambios Estados
                    </a>
                  )}
                </div>
                )}
              </div>

              <div className='d-flex flex-wrap flex-stack'>
                <div className='d-flex flex-column flex-grow-1 pe-8'>
                  <div className='d-flex flex-wrap'>
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr066.svg'
                          className='svg-icon-3 svg-icon-success me-2'
                        />
                        <div className='fs-2 fw-bolder'>{values.totalEarning}</div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400'>Ingresos</div>
                    </div>

                    {/* <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr065.svg'
                        className='svg-icon-3 svg-icon-danger me-2'
                      />
                      <div className='fs-2 fw-bolder'>75</div>
                    </div>

                    <div className='fw-bold fs-6 text-gray-400'>Projects</div>
                  </div> */}

                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr066.svg'
                          className='svg-icon-3 svg-icon-success me-2'
                        />
                        <div className='fs-2 fw-bolder'>{values.requestedAmount}</div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400'>Monto Solicitado</div>
                    </div>
                  </div>
                </div>

                {/* <div className='d-flex align-items-center w-200px w-sm-300px flex-column mt-3'>
                <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
                  <span className='fw-bold fs-6 text-gray-400'>Profile Compleation</span>
                  <span className='fw-bolder fs-6'>50%</span>
                </div>
                <div className='h-5px mx-3 w-100 bg-light mb-3'>
                  <div
                    className='bg-success rounded h-5px'
                    role='progressbar'
                    style={{width: '50%'}}
                  ></div>
                </div>
              </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Detalles del perfil</h3>
          </div>

          {/* <Link to='/crafted/account/settings' className='btn btn-primary align-self-center'>
            Editar
          </Link> */}
        </div>

        <div className='card-body p-9'>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Tipo de Identificación</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>
                {values.identificationType === '1' ? 'Cédula' : 'Pasaporte'}
              </span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>No. Identificación</label>

            <div className='col-lg-8 d-flex align-items-center'>
              <span className='fw-bolder fs-6 me-2'>{values.identificationNo}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>¿Ingresos superan los RD$35,000?</label>

            <div className='col-lg-8'>
              <a className='fw-bold fs-6 text-dark text-hover-primary'>
                {values.familiarAmount ? 'Sí' : 'No'}
              </a>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{values.requestType === "RT001" ? 'Ocupación': 'Actividad de la empresa'}</label>

            <div className='col-lg-8'>
              <a className='fw-bold fs-6 text-dark text-hover-primary'>
                {values.occupancy}
              </a>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Score</label>
            {values.statusCode === 'RQ003' && (
               <div className='col-lg-8'>
               <a className='fw-bold fs-6 text-dark text-hover-primary'>
                 {values.score}
               </a>
             </div>
            )}
            {values.statusCode !== 'RQ003' &&(
            <div className='col-lg-4' style={{display: "flex", marginRight: '10px'}}>
              <input
                onChange={(e) => HandleScore(e.target.value)}
                value={manualScore}
                className='form-control bg-transparent'
                type='number'
                autoComplete='off'
                placeholder='Ingrese score manual...'
              />
              <div className='d-flex  flex-shrink-0' style={{marginLeft: '10px', marginTop: '5px'}}>
                <Tooltip title='Guardar'>
                  <a
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    onClick={() => updateScore(manualScore)}
                  >
                    <i className='bi bi-check2-circle'></i>
                  </a>
                </Tooltip>
              </div>
            </div>
            )}
          </div>
        </div>
      </div>
      <ChangeReqeustStatusModal
        show={modal}
        handleClose={() => handleCloseModal()}
        updateRequest={(value) => updateRequest(value)}
      />
    </>
  )
}
