/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useRef, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {PageLink, PageTitle} from './../../../_metronic/layout/core'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import Swal from 'sweetalert2'
import Tooltip from '@mui/material/Tooltip'
// --COMPONENT
import {TableLoader} from '../../components/TableLoader'
// --PERMISSIONS
import CheckInputPermission from '../../core/CheckInputPermission'
import {PermissionModel} from '../../core/_models'
// ---HTTP URL
import {GET_REQUEST_REASON_REQUEST} from '../prospect-forms/core/_request'

const RolesBreadcrumbs: Array<PageLink> = [
  {
    title: 'Configuracion',
    path: '',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Razones',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const headers: Array<any> = ['Descripción', 'Acciones']

export default function RequestReason() {
  const [loading, setLoading] = useState(false)
  const [reasonList, setReasonList] = useState<any[]>([])
  const [userPermission, setUserPermission] = useState<PermissionModel>()

  useEffect(() => {
    GET_REQUEST_REASON_REQUEST_FUNC()
    setUserPermission(CheckInputPermission('Razones del prestamo'))
  }, [])

  async function GET_REQUEST_REASON_REQUEST_FUNC() {
    setLoading(true)
    const {data: result} = await GET_REQUEST_REASON_REQUEST()
    setReasonList(result.data)
    setLoading(false)
  }

  return (
    <>
      <PageTitle breadcrumbs={RolesBreadcrumbs}>
        Listado de razones solicitud del personal/empresarial
      </PageTitle>
      <div className={`card ${'Table'}`}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-1 mb-1'>Solicitudes Personales</span>
            <span className='text-muted mt-1 fw-semibold fs-5'>
              Listado de Solicitudes personales via formulario
            </span>
          </h3>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Agregar'
            // onClick={() => openModalFunc()}
          >
            <a className='btn btn-sm btn-light-primary'>
              <i className='bi bi-plus'></i>
              Agregar Nuevo
            </a>
          </div>
        </div>

        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
                <tr>
                  {headers.map((data, index) => (
                    <>
                      <th key={index} className='min-w-150px text-muted '>
                        {data.toUpperCase()}
                      </th>
                    </>
                  ))}
                </tr>
              </thead>
              {loading && <TableLoader />}
              <tbody>
                {reasonList.map((data, index) => (
                  <tr key={index}>
                    <td>
                      <a className='text-dark fw-bold text-hover-primary d-block fs-6'>
                        {data.description}
                      </a>
                    </td>
                    <td>
                      {userPermission?.update && (
                        <Tooltip title='Editar'>
                          <a
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            //   onClick={() => EditOnModal(data)}
                          >
                            <KTSVG
                              path='/media/icons/duotune/art/art005.svg'
                              className='svg-icon-3'
                            />
                          </a>
                        </Tooltip>
                      )}
                      {userPermission?.delete && (
                        <Tooltip title='Eliminar'>
                          <a
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                            //   onClick={() => DELETE_ROLE_REQUEST_FUNC(data.id)}
                          >
                            <KTSVG
                              path='/media/icons/duotune/general/gen027.svg'
                              className='svg-icon-3'
                            />
                          </a>
                        </Tooltip>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}
