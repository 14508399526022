/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useRef, useEffect} from 'react'
import clsx from 'clsx'
import {createPortal} from 'react-dom'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../_metronic/helpers'
import Swal from 'sweetalert2'
// --ENDPOINT
import {ChangePassword} from '../modules/auth/core/_requests'

const passwordSchema = Yup.object().shape({
  Password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Contraseña es obligatoria'),
})

const initialValues = {
  Email: '',
  Password: '',
}

type Props = {
  show: boolean
  emailProp: string
  handleClose: () => void
}
const modalsRoot = document.getElementById('root-modals') || document.body

const ChangePasswordModal = ({show, emailProp, handleClose}: Props) => {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    formik.resetForm()
  }, [])

  const formik = useFormik({
    initialValues,
    validationSchema: passwordSchema,
    onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
      setLoading(true)
      values.Email = emailProp
      console.log('values', values)

      try {
        const {data: result} = await ChangePassword(values)
        if (result?.isSuccess) {
          resetForm()
          Swal.fire({
            title: 'Enhorabuena!',
            text: 'Los datos han sido actualizado correctamente',
            icon: 'success',
          })
          handleClose()
          setLoading(false)
        } else {
          Swal.fire({
            title: 'Información',
            text: 'Algo ha salido mal. Contactar soporte técnico',
            icon: 'info',
          })
        }
      } catch (error) {
        Swal.fire({
          title: 'Información',
          text: 'Algo ha salido mal. Contactar soporte técnico',
          icon: 'info',
        })
      }
    },
  })

  return createPortal(
    <Modal
      id='change_password'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-600px'
      show={show}
      onHide={handleClose}
      //   onEntered={loadStepper}
      backdrop={true}
    >
      <div className='modal-header'>
        <h2>Cambiar Contraseña</h2>
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
      </div>
      <div className='modal-body py-lg-10 px-lg-10'>
        <form
          className='form w-100'
          onSubmit={formik.handleSubmit}
          noValidate
          id='kt_login_signin_form'
        >
          <div className='fv-row mb-3'>
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Contraseña</label>
            <input
              type='password'
              autoComplete='off'
              {...formik.getFieldProps('Password')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.Password && formik.errors.Password,
                },
                {
                  'is-valid': formik.touched.Password && !formik.errors.Password,
                }
              )}
            />
            {formik.touched.Password && formik.errors.Password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.Password}</span>
                </div>
              </div>
            )}
          </div>
          <div className='d-grid mb-10'>
            <button
              type='submit'
              id='kt_sign_in_submit'
              className='btn btn-primary'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && <span className='indicator-label'>Continuar</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Espere...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </Modal>,
    modalsRoot
  )
}

export {ChangePasswordModal}
