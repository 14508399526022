/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Swal from 'sweetalert2'
// ---HTTP URL
import { CREATE_PERSONAL_REQUEST_URL_REQUEST, VALIDATE_CEDULA_REQUEST, GET_REQUEST_REASON_REQUEST } from './core/_request'

const personalSchema = Yup.object().shape({
  names: Yup.string()
    .min(3, 'Mínimo 3 carácteres')
    .max(50, 'Máximo 50 carácteres')
    .required('Nombres son obligatorio'),
  lastNames: Yup.string()
    .min(3, 'Mínimo 3 carácteres')
    .max(50, 'Máximo 50 carácteres')
    .required('Apellidos son obligatorio'),
  identificationNo: Yup.string()
    .min(3, 'Mínimo 3 carácteres')
    .max(50, 'Máximo 50 carácteres')
    .required('No. Identificación es obligatorio'),
  phone_number: Yup.string()
    .min(10, 'Mínimo 10 numeros')
    .max(10, 'Máximo 10 numeros')
    .required('Ceuluar es obligatorio'),
  email: Yup.string()
    .email('Formato del correo es invalido')
    .min(3, 'Mínimo 3 carácteres')
    .max(50, 'Máximo 50 carácteres')
    .required('Correo electrónico es obligatorio'),
  totalEarning: Yup.string().required('Total Ingresos es obligatorio'),
  requestedAmount: Yup.string().required('Total Ingresos es obligatorio'),
  occupancy: Yup.string().required('Ocupación es obligatoria'),
  reasonRequest: Yup.string().required('Razón es obligatorio'),
})

let initValues = {
  names: '',
  lastNames: '',
  identificationType: '1',
  identificationNo: '',
  phone_number: '',
  telephone: '',
  email: '',
  contactPerson: '',
  totalEarning: '',
  requestedAmount: '',
  reasonRequest: 0,
  familiarAmount: false,
  occupancy: '',
  comments: '',
  statusCode: 'RQ001',
  requestType: 'RT001',
  score: 0
}

const reasonList = [
  { label: 'Deseo recibir información', value: 1 },
  { label: 'Solicitar un préstamo', value: 2 },
  { label: 'Estoy interesado/a, pero no tengo vehículo', value: 3 },
  { label: 'Quiero saber si aplico', value: 4 },
]

export default function Personal() {
  const [initialValues, setInitialValues] = useState(initValues)
  const [loading, setLoading] = useState(false)
  const [selectedType, setSelectedType] = useState('1')
  const [amountRadio, setAmountRadio] = useState(true)
  const [acceptTerms, setAcceptTerms] = useState(false)
  const [requestReasonLit, setRequestReasonList] = useState<any[]>([])

  useEffect(() => {
    GET_REQUEST_REASON_REQUEST_FUNCTION();
  }, [])

  const formik = useFormik({
    initialValues,
    validationSchema: personalSchema,
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      setSubmitting(true)
      setLoading(true)
      CREATE(values)
      setAcceptTerms(false)
      setSubmitting(false)
      setLoading(false)
    },
  })

  async function GET_REQUEST_REASON_REQUEST_FUNCTION() {
    setLoading(true)
    const { data: result } = await GET_REQUEST_REASON_REQUEST()
    setRequestReasonList(result.data)
    setLoading(false)
  }

  async function CREATE(object: any) {
    object.reasonRequest = Number(object.reasonRequest)
    try {
      if (object.identificationType === "1") {
        var results = await VALIDATE_CEDULA(object.identificationNo)
        if (!results) {
          Swal.fire({
            title: 'Informacion',
            text: 'La cédula es incorrecta. Favor verificar',
            icon: 'info',
          })
          return;
        }
      }
      const { data: result } = await CREATE_PERSONAL_REQUEST_URL_REQUEST(object)
      if (result?.isSuccess) {
        Swal.fire({
          title: 'Enhorabuena!',
          text: 'La solicitud ha sido enviada correctamente!',
          icon: 'success',
        })
        formik.resetForm()
        setLoading(false)
      } else {
        Swal.fire({
          title: 'Información',
          text: 'Algo ha salido mal. Favor contactarnos al 809-000-0000',
          icon: 'info',
        })
      }
    } catch (error) {
      setLoading(false)
      Swal.fire({
        title: 'Informacion',
        text: 'Algo ha salido mal. Favor contactarnos al 809-000-0000',
        icon: 'info',
      })
    }
  }

  async function VALIDATE_CEDULA(cedula: string) {
    try {
      const { data: result } = await VALIDATE_CEDULA_REQUEST(cedula)
      return result.valid;
    } catch (error) {
      setLoading(false)
    }
  }

  return (
    <>
      <h1 className='fw-bolder fs-2hx text-gray-900 mb-4'>Solicitud Crédito Personal</h1>
      <div className=' py-lg-10 px-lg-10'>
        <form className='form w-100' onSubmit={formik.handleSubmit} id='persona_prospect_form'>
          <div className='fv-row mb-3'>
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Nombres</label>
            <input
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('names')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.names && formik.errors.names,
                },
                {
                  'is-valid': formik.touched.names && !formik.errors.names,
                }
              )}
            />
            {formik.touched.names && formik.errors.names && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.names}</span>
                </div>
              </div>
            )}
          </div>

          <div className='fv-row mb-3'>
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Apellidos</label>
            <input
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('lastNames')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.lastNames && formik.errors.lastNames,
                },
                {
                  'is-valid': formik.touched.lastNames && !formik.errors.lastNames,
                }
              )}
            />
            {formik.touched.lastNames && formik.errors.lastNames && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.lastNames}</span>
                </div>
              </div>
            )}
          </div>

          <div className='d-flex ' style={{ marginTop: 20 }}>
            <div className='form-check form-check-custom form-check-success form-check-solid form-check-sm ms-10'>
              <input
                className='form-check-input'
                type='radio'
                checked={selectedType === '1'}
                onChange={() => {
                  setSelectedType('1')
                  formik.setFieldValue('identificationType', '1')
                }}
              />
              <label className='form-check-label text-gray-700 fw-bold text-nowrap'>Cédula</label>
            </div>
            <div className='form-check form-check-custom form-check-success form-check-solid form-check-sm ms-10'>
              <input
                className='form-check-input'
                type='radio'
                checked={selectedType === '2'}
                onChange={() => {
                  setSelectedType('2')
                  formik.setFieldValue('identificationType', '2')
                }}
              />
              <label className='form-check-label text-gray-700 fw-bold text-nowrap'>
                Pasaporte
              </label>
            </div>
          </div>

          <div className='fv-row mb-3' style={{ marginTop: 20 }}>
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>No. Identificación</label>
            <input
              // type='number'
              autoComplete='off'
              {...formik.getFieldProps('identificationNo')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.identificationNo && formik.errors.identificationNo,
                },
                {
                  'is-valid': formik.touched.identificationNo && !formik.errors.identificationNo,
                }
              )}
            />
            {formik.touched.identificationNo && formik.errors.identificationNo && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.identificationNo}</span>
                </div>
              </div>
            )}
          </div>

          <div className='fv-row mb-3'>
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Celular</label>
            <input
              // type='number'
              autoComplete='off'
              {...formik.getFieldProps('phone_number')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.phone_number && formik.errors.phone_number,
                },
                {
                  'is-valid': formik.touched.phone_number && !formik.errors.phone_number,
                }
              )}
            />
            {formik.touched.phone_number && formik.errors.phone_number && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.phone_number}</span>
                </div>
              </div>
            )}
          </div>

          <div className='fv-row mb-3'>
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Correo Electrónico</label>
            <input
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.email && formik.errors.email,
                },
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              </div>
            )}
          </div>

          <div className='fv-row mb-3'>
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Total Ingresos(RD$)</label>
            <input
              // type='number'
              autoComplete='off'
              {...formik.getFieldProps('totalEarning')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.totalEarning && formik.errors.totalEarning,
                },
                {
                  'is-valid': formik.touched.totalEarning && !formik.errors.totalEarning,
                }
              )}
            />
            {formik.touched.totalEarning && formik.errors.totalEarning && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.totalEarning}</span>
                </div>
              </div>
            )}
          </div>

          <div className='fv-row mb-3'>
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>
              Monto Solicitado(RD$)
            </label>
            <input
              // type='number'
              autoComplete='off'
              {...formik.getFieldProps('requestedAmount')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.requestedAmount && formik.errors.requestedAmount,
                },
                {
                  'is-valid': formik.touched.requestedAmount && !formik.errors.requestedAmount,
                }
              )}
            />
            {formik.touched.requestedAmount && formik.errors.requestedAmount && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.requestedAmount}</span>
                </div>
              </div>
            )}
          </div>

          <div className='fv-row mb-3'>
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Ocupación</label>
            <textarea
              placeholder='Describa su ocupación'
              autoComplete='off'
              {...formik.getFieldProps('occupancy')}
              className='form-control bg-transparent'
            />
          </div>

          <div className='col-lg-12 fv-row'>
            <select
              className='form-select form-select-solid form-select-lg'
              {...formik.getFieldProps('reasonRequest')}
            >
              <option value=''>Razón de la solicitud..</option>
              {requestReasonLit.map((data, index) => (
                <option key={index} value={data.id}>
                  {data.description}
                </option>
              ))}
            </select>
            {formik.touched.reasonRequest && formik.errors.reasonRequest && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.reasonRequest}</div>
              </div>
            )}
          </div>

          <div className='d-flex' style={{ marginTop: 20 }}>
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>
              ¿Percibe ingresos familiares de RD$35,000 o más?
            </label>
            <div className='form-check form-check-custom form-check-success form-check-solid form-check-sm ms-10'>
              <input
                className='form-check-input'
                type='radio'
                checked={amountRadio}
                onChange={() => {
                  setAmountRadio(true)
                  formik.setFieldValue('familiarAmount', true)
                }}
                value='1'
                id='kt_builde'
                name='model.app.header.default.content'
              />
              <label
                className='form-check-label text-gray-700 fw-bold text-nowrap'
                htmlFor='kt_builde'
              >
                Sí
              </label>
            </div>
            <div className='form-check form-check-custom form-check-success form-check-solid form-check-sm ms-10'>
              <input
                className='form-check-input'
                type='radio'
                value='page-title'
                id='kt_builder'
                checked={!amountRadio}
                onChange={() => {
                  setAmountRadio(false)
                  formik.setFieldValue('familiarAmount', false)
                }}
              />
              <label
                className='form-check-label text-gray-700 fw-bold text-nowrap'
                htmlFor='kt_builder'
              >
                No
              </label>
            </div>
          </div>
          <div className='d-flex' style={{ marginTop: 20, textAlign: 'left' }}>
            <label className='form-check form-check-inline form-check-solid me-5'>
              <input
                className='form-check-input'
                name='terms[]'
                type='checkbox'
                defaultChecked={acceptTerms}
                onChange={() => {
                  setAcceptTerms(true)
                }}
              />
              <span className='fw-bold ps-2 fs-8'>
                Las informaciones contenidas otorgan consentimiento expreso para que la entidad
                pueda efectuar consultas sobre información crediticia en las bases de datos de las
                Sociedades de Información Crediticia para fines de evaluación crediticia, así como a
                suministrar a dichas sociedades información relativa a las operaciones con los
                suscritos, en la medida en que la ley así lo permita, con el objeto de confirmar y
                actualizar sus bases de datos.
              </span>
            </label>
          </div>
          <div className='d-grid mb-10' style={{ marginTop: 10 }}>
            <button
              type='submit'
              id='kt_sign_in_submit'
              className='btn btn-primary'
              disabled={formik.isSubmitting || (!formik.isValid && acceptTerms === false)}
            >
              {!loading && <span className='indicator-label'>Enviar Solicitud</span>}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Espere...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  )
}
