import axios from '../../../api/api'

// --API ROUTE
const API_URL = process.env.REACT_APP_API_URL

// --ENDPOINTS ROUTES -personal
export const CREATE_PERSONAL_REQUEST_URL = `${API_URL}/api/Request/PostCreditRequest`

// --ENDPOINTS ROUTES -business
export const CREATE_BUSINESS_REQUEST_URL = `${API_URL}/api/Request/PostCreditRequest`

export const GET_REQUEST_REASON = `${API_URL}/api/Request/GetRequestReason`

export const VALIDATE_CEDULA = 'https://api.digital.gob.do/v3/cedulas/'

//ACTUAL REQUEST
export function CREATE_PERSONAL_REQUEST_URL_REQUEST(request: any) {
  return axios.post(CREATE_PERSONAL_REQUEST_URL, request)
}

//ACTUAL REQUEST
export function CREATE_BUSINESS_REQUEST_URL_REQUEST(request: any) {
  return axios.post(CREATE_BUSINESS_REQUEST_URL, request)
}

export function GET_REQUEST_REASON_REQUEST() {
  return axios.get(GET_REQUEST_REASON)
}

export function VALIDATE_CEDULA_REQUEST(cedula : string) {
  return axios.get(VALIDATE_CEDULA + cedula + '/validate')
}
