import axios from '../../../api/api'

// --API ROUTE
const API_URL = process.env.REACT_APP_API_URL
// --ENDPOINTS GET_CREDIT_LEVEL_LIST
export const GET_CREDIT_LEVEL_LIST = `${API_URL}/api/CreditLevel`
// --ENDPOINTS CREATE_CREDIT_LEVEL
export const CREATE_CREDIT_LEVEL = `${API_URL}/api/CreditLevel`
// --ENDPOINTS UPDATE_CREDIT_LEVEL
export const UPDATE_CREDIT_LEVEL = `${API_URL}/api/CreditLevel`
// --ENDPOINTS DELETE_CREDIT_LEVEL
export const DELETE_CREDIT_LEVEL = `${API_URL}/api/CreditLevel?id=`

//ACTUAL REQUEST
export function GET_CREDIT_LEVEL_LIST_REQUEST() {
  return axios.get(GET_CREDIT_LEVEL_LIST)
}

export function CREATE_CREDIT_LEVEL_REQUEST(request: any) {
  return axios.post(CREATE_CREDIT_LEVEL, request)
}

export function UPDATE_CREDIT_LEVEL_REQUEST(request: any) {
  return axios.put(UPDATE_CREDIT_LEVEL, request)
}

export function DELETE_CREDIT_LEVEL_REQUEST(id: any) {
  return axios.delete(`${DELETE_CREDIT_LEVEL}${id}`)
}
