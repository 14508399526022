/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { createPortal } from 'react-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../../_metronic/helpers'
import Swal from 'sweetalert2'

// --ENDPOINTS
import {
    CREATE_CREDIT_LEVEL_REQUEST,
    UPDATE_CREDIT_LEVEL_REQUEST,
} from '../../pages/credit-levels-manager/core/_request'

const roleSchema = Yup.object().shape({
    letter: Yup.string()
        .min(1, 'Minimum 1 symbols')
        .max(2, 'Maximum 50 symbols')
        .required('Letra / Nivel es obligatorio'),
    from: Yup.number().required('Desde es'),
    to: Yup.number().required('Hasta es obligatorio')
})

let initValues = {
    id: 0,
    letter: "",
    from: 0,
    to: 0
}

type Props = {
    show: boolean
    valuesEdit: typeof initValues
    //   hasSaved: () => boolean
    handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const AddCreditLevel = ({ show, valuesEdit, handleClose }: Props) => {
    const [initialValues, setInitialValues] = useState(initValues)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        formik.setValues(valuesEdit)
    }, [valuesEdit])
    function closingModal() {
        initialValues.letter = ''
        initialValues.to = 0
        initialValues.from = 0
        handleClose()
    }
    const formik = useFormik({
        initialValues,
        validationSchema: roleSchema,

        onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
            setSubmitting(true)
            setLoading(true)
            console.log("values", values);
            values.from = Number(values.from)
            values.to = Number(values.to)
            values.id !== 0 ? UPDATE(values) : CREATE(values)
            resetForm()
            setSubmitting(false)
            setLoading(false)
        },
    })

    async function CREATE(object: any) {
        try {
            const { data: result } = await CREATE_CREDIT_LEVEL_REQUEST(object.name)
            if (result?.isSuccess) {
                Swal.fire({
                    title: 'Enhorabuena!',
                    text: 'El rol ha sido creado exitosamente',
                    icon: 'success',
                })
                closingModal()
                setLoading(false)
            } else {
                Swal.fire({
                    title: 'Información',
                    text: 'Algo ha salido mal. Contactar soporte técnico',
                    icon: 'info',
                })
            }
        } catch (error) {
            setLoading(false)
            console.log(error)
            Swal.fire({
                title: 'Informacion',
                text: 'Algo ha salido mal. Contactar soporte técnico',
                icon: 'info',
            })
        }
    }

    async function UPDATE(object: any) {
        try {
            const { data: result } = await UPDATE_CREDIT_LEVEL_REQUEST(object)
            console.log('result', result)
            if (result?.isSuccess) {
                Swal.fire({
                    title: 'Enhorabuena!',
                    text: 'El rol ha sido actualizado exitosamente',
                    icon: 'success',
                })
                closingModal()
                setLoading(false)
            } else {
                Swal.fire({
                    title: 'Información',
                    text: 'Algo ha salido mal. Contactar soporte técnico',
                    icon: 'info',
                })
            }
        } catch (error) {
            setLoading(false)
            console.log(error)
            Swal.fire({
                title: 'Informacion',
                text: 'Algo ha salido mal. Contactar soporte técnico',
                icon: 'info',
            })
        }
    }

    return createPortal(
        <Modal id='add_role'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-600px'
            show={show}
            onHide={handleClose}
            backdrop={true}>
            <div className='modal-header'>
                <h2>Agregar Nuevo Nivel</h2>
                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>
            <div className='modal-body py-lg-10 px-lg-10'>
                <form className='form w-100' onSubmit={formik.handleSubmit} id='kt_login_signin_form'>
                    <div className='fv-row mb-3'>
                        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Letra / Nivel</label>
                        <input
                            value={formik.values.letter}
                            type='text'
                            autoComplete='off'
                            onChange={formik.handleChange('letter')}
                            className={clsx(
                                'form-control bg-transparent',
                                {
                                    'is-invalid': formik.touched.letter && formik.errors.letter,
                                },
                                {
                                    'is-valid': formik.touched.letter && !formik.errors.letter,
                                }
                            )}
                        />
                        {formik.touched.letter && formik.errors.letter && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors.letter}</span>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='fv-row mb-3'>
                        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Desde</label>
                        <input
                            value={formik.values.from}
                            type='text'
                            autoComplete='off'
                            onChange={formik.handleChange('from')}
                            className={clsx(
                                'form-control bg-transparent',
                                {
                                    'is-invalid': formik.touched.from && formik.errors.from,
                                },
                                {
                                    'is-valid': formik.touched.from && !formik.errors.from,
                                }
                            )}
                        />
                        {formik.touched.from && formik.errors.from && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors.from}</span>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='fv-row mb-3'>
                        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Hasta</label>
                        <input
                            value={formik.values.to}
                            type='text'
                            autoComplete='off'
                            onChange={formik.handleChange('to')}
                            className={clsx(
                                'form-control bg-transparent',
                                {
                                    'is-invalid': formik.touched.to && formik.errors.to,
                                },
                                {
                                    'is-valid': formik.touched.to && !formik.errors.to,
                                }
                            )}
                        />
                        {formik.touched.to && formik.errors.to && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors.to}</span>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='d-grid mb-10'>
                        <button
                            type='submit'
                            id='kt_sign_in_submit'
                            className='btn btn-primary'
                            disabled={formik.isSubmitting || !formik.isValid}
                        >
                            {!loading && <span className='indicator-label'>Continuar</span>}
                            {loading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Espere...
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </Modal>, modalsRoot
    )
}

export default AddCreditLevel
