import React, {useEffect, useState} from 'react'
import {Outlet} from 'react-router-dom'
import {useThemeMode} from '../../../_metronic/partials'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
// --RADIO FORMS
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
// FORM IMPORTS
import Personal from './Personal'
import Business from './Business'

const BODY_CLASSES = ['bgi-size-cover', 'bgi-position-center', 'bgi-no-repeat']

export default function ProspectWrapper() {
  const [selectedForm, setSelectedForm] = useState('personal')
  const {mode} = useThemeMode()

  useEffect(() => {
    BODY_CLASSES.forEach((c) => document.body.classList.add(c))
    document.body.style.backgroundImage =
      mode === 'dark'
        ? `url(${toAbsoluteUrl('/media/auth/bg7-dark.jpg')})`
        : `url(${toAbsoluteUrl('/media/auth/bg7.jpg')})`

    return () => {
      BODY_CLASSES.forEach((c) => document.body.classList.remove(c))
      document.body.style.backgroundImage = 'none'
    }
  }, [mode])

  function handleSelectedForm(value: string) {
    setSelectedForm(value)
  }

  return (
    <>
      <div className='d-flex flex-column flex-root'>
        <div className='d-flex flex-column flex-center flex-column-fluid'>
          <div className='d-flex flex-column flex-center text-center p-10'>
            <div className='card card-flush  w-lg-650px py-5'>
              <div className='card-body py-15 py-lg-20'>
                <FormControl>
                  {/* <FormLabel id='demo-row-radio-buttons-group-label'>Gender</FormLabel> */}
                  <RadioGroup
                    row
                    aria-labelledby='demo-row-radio-buttons-group-label'
                    name='row-radio-buttons-group'
                  >
                    <FormControlLabel
                      value='personal'
                      control={
                        <Radio
                          checked={selectedForm === 'personal'}
                          onChange={() => handleSelectedForm('personal')}
                        />
                      }
                      label='Personal'
                    />
                    <FormControlLabel
                      value='business'
                      control={
                        <Radio
                          checked={selectedForm === 'business'}
                          onChange={() => handleSelectedForm('business')}
                        />
                      }
                      label='Empresarial'
                    />
                  </RadioGroup>
                </FormControl>
                <div style={{marginTop: 10}}>
                  {selectedForm === 'personal' && <Personal />}
                  {selectedForm === 'business' && <Business />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
