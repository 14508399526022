/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Swal from 'sweetalert2'
import { CREATE_BUSINESS_REQUEST_URL_REQUEST, GET_REQUEST_REASON_REQUEST } from './core/_request'

const businessSchema = Yup.object().shape({
  names: Yup.string()
    .min(3, 'Mínimo 3 carácteres')
    .max(50, 'Máximo 50 carácteres')
    .required('Nombres son obligatorio'),
  telephone: Yup.string()
    .min(10, 'Mínimo 10 numeros')
    .max(10, 'Máximo 10 numeros')
    .required('Ceuluar es obligatorio'),
  phone_number: Yup.string()
    .min(10, 'Mínimo 10 numeros')
    .max(10, 'Máximo 10 numeros')
    .required('Ceuluar es obligatorio'),
  email: Yup.string()
    .email('Formato del correo es invalido')
    .min(3, 'Mínimo 3 carácteres')
    .max(50, 'Máximo 50 carácteres')
    .required('Correo electrónico es obligatorio'),
  identificationNo: Yup.string().required('Total Ingresos es obligatorio'),
  contactPerson: Yup.string().required('Total Ingresos es obligatorio'),
  occupancy: Yup.string().required('Actividad de la empresa es obligatoria'),
  reasonRequest: Yup.string().required('Razón es obligatorio'),
})

let initValues = {
  names: '',
  lastNames: '',
  identificationType: '3',
  identificationNo: '',
  phone_number: '',
  telephone: '',
  email: '',
  contactPerson: '',
  totalEarning: '',
  requestedAmount: '',
  reasonRequest: 0,
  familiarAmount: false,
  occupancy: '',
  comments: '',
  statusCode: 'RQ001',
  requestType: 'RT002',
  score : 0
}

export default function Business() {
  const [initialValues, setInitialValues] = useState(initValues)
  const [loading, setLoading] = useState(false)
  const [requestReasonLit, setRequestReasonList] = useState<any[]>([])


  useEffect(() => {
    GET_REQUEST_REASON_REQUEST_FUNCTION();
  }, [])

  async function GET_REQUEST_REASON_REQUEST_FUNCTION() {
    setLoading(true)
    const { data: result } = await GET_REQUEST_REASON_REQUEST()
    setRequestReasonList(result.data)
    setLoading(false)
  }


  const formik = useFormik({
    initialValues,
    validationSchema: businessSchema,
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      setSubmitting(true)
      setLoading(true)
      CREATE(values)
      setSubmitting(false)
      setLoading(false)
    },
  })

  async function CREATE(object: any) {
    console.log('object', object)
    try {
      const { data: result } = await CREATE_BUSINESS_REQUEST_URL_REQUEST(object)
      if (result?.isSuccess) {
        Swal.fire({
          title: 'Enhorabuena!',
          text: 'La solicitud ha sido enviada correctamente!',
          icon: 'success',
        })
        formik.resetForm()
        setLoading(false)
      } else {
        Swal.fire({
          title: 'Información',
          text: 'Algo ha salido mal. Favor contactarnos al 809-000-0000',
          icon: 'info',
        })
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
      Swal.fire({
        title: 'Informacion',
        text: 'Algo ha salido mal. Favor contactarnos al 809-000-0000',
        icon: 'info',
      })
    }
  }

  return (
    <>
      <h1 className='fw-bolder fs-2hx text-gray-900 mb-4'>Solicitud Crédito Empresarial</h1>
      <div className=' py-lg-10 px-lg-10'>
        <form className='form w-100' onSubmit={formik.handleSubmit} id='nusiness_prospect_form'>
          <div className='fv-row mb-3'>
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Nombres</label>
            <input
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('names')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.names && formik.errors.names,
                },
                {
                  'is-valid': formik.touched.names && !formik.errors.names,
                }
              )}
            />
            {formik.touched.names && formik.errors.names && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.names}</span>
                </div>
              </div>
            )}
          </div>

          <div className='fv-row mb-3'>
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>RNC</label>
            <input
              // type='number'
              autoComplete='off'
              {...formik.getFieldProps('identificationNo')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.identificationNo && formik.errors.identificationNo,
                },
                {
                  'is-valid': formik.touched.identificationNo && !formik.errors.identificationNo,
                }
              )}
            />
            {formik.touched.identificationNo && formik.errors.identificationNo && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.identificationNo}</span>
                </div>
              </div>
            )}
          </div>

          <div className='fv-row mb-3'>
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Persona de Contacto</label>
            <input
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('contactPerson')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.contactPerson && formik.errors.contactPerson,
                },
                {
                  'is-valid': formik.touched.contactPerson && !formik.errors.contactPerson,
                }
              )}
            />
            {formik.touched.contactPerson && formik.errors.contactPerson && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.contactPerson}</span>
                </div>
              </div>
            )}
          </div>

          <div className='fv-row mb-3'>
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Correo Electrónico</label>
            <input
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.email && formik.errors.email,
                },
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              </div>
            )}
          </div>

          <div className='fv-row mb-3'>
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Teléfono</label>
            <input
              // type='number'
              autoComplete='off'
              {...formik.getFieldProps('telephone')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.telephone && formik.errors.telephone,
                },
                {
                  'is-valid': formik.touched.telephone && !formik.errors.telephone,
                }
              )}
            />
            {formik.touched.telephone && formik.errors.telephone && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.telephone}</span>
                </div>
              </div>
            )}
          </div>

          <div className='fv-row mb-3'>
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Celular</label>
            <input
              // type='number'
              autoComplete='off'
              {...formik.getFieldProps('phone_number')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.phone_number && formik.errors.phone_number,
                },
                {
                  'is-valid': formik.touched.phone_number && !formik.errors.phone_number,
                }
              )}
            />
            {formik.touched.phone_number && formik.errors.phone_number && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.phone_number}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-3'>
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Actividad de la empresa</label>
            <textarea
              placeholder='Describir las actividades de la empresa'
              autoComplete='off'
              {...formik.getFieldProps('occupancy')}
              className='form-control bg-transparent'
            />
          </div>
          <div className='col-lg-12 fv-row'>
            <select
              className='form-select form-select-solid form-select-lg'
              {...formik.getFieldProps('reasonRequest')}
            >
              <option value=''>Razón de la solicitud..</option>
              {requestReasonLit.map((data, index) => (
                <option key={index} value={data.id}>
                  {data.description}
                </option>
              ))}
            </select>
            {formik.touched.reasonRequest && formik.errors.reasonRequest && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.reasonRequest}</div>
              </div>
            )}
          </div>
          <div className='fv-row mb-3'>
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Comentarios</label>
            <textarea
              autoComplete='off'
              {...formik.getFieldProps('comments')}
              className='form-control bg-transparent'
            />
          </div>
          <div className='d-grid mb-10' style={{ marginTop: 10 }}>
            <button
              type='submit'
              id='kt_sign_in_submit'
              className='btn btn-primary'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && <span className='indicator-label'>Enviar Solicitud</span>}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Espere...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  )
}
