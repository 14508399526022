/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { KTSVG } from '../../_metronic/helpers'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { Dropdown1 } from '../../_metronic/partials/content/dropdown/Dropdown1'
import { useThemeMode } from '../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
// '../../layout/theme-mode/ThemeModeProvider'
type Props = {
  className: string
  chartColor: string
  chartHeight: string
  letter: string
  score: number
}

const RoundedChart: React.FC<Props> = ({ className, chartColor, chartHeight, letter, score }) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const { mode } = useThemeMode()
  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(chartRef.current, chartOptions(chartColor, chartHeight))
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode])

  function returnColorOnScore(score : number){
      var color = '#C6C6C6';
      if (score >= 500 && score <= 579) {
        color = '#BD1F29';
      }
      if (score >= 580 && score <= 619) {
        color = '#F04721';
      }
      if (score >= 620 && score <= 659) {
        color = '#F58E22';
      }
      if (score >= 660 && score <= 669) {
        color = '#FFCB0B';
      }
      if (score >= 700 && score <= 759) {
        color = '#7EBA44';
      }
      if (score >= 760 && score <= 850) {
        color = '#0F9148';
      }
      return color;
  }

  function returnTextOnScore(score : number){
    var text = 'No Datos';
    if (score >= 500 && score <= 579) {
      text = 'Muy Malo';
    }
    if (score >= 580 && score <= 619) {
      text = 'Bajo';
    }
    if (score >= 620 && score <= 659) {
      text = 'Regular';
    }
    if (score >= 660 && score <= 669) {
      text = 'Bueno';
    }
    if (score >= 700 && score <= 759) {
      text = 'Muy Bueno';
    }
    if (score >= 760 && score <= 850) {
      text = 'Excelente';
    }
    return text;
}

  return (
    <div className={`card ${className}`}>
      <div style={{ display: "flex", width: '100%' }}>
        {/* 1  */}
        <div style={{ width: '100%' }}>
          <div className='card-header border-0 py-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-3 mb-1'>Score de crédito SIGCI</span>
              <span className='text-muted fw-semibold fs-7'>Score del cliente</span>
            </h3>
          </div>
          <div className='card-body d-flex flex-column col-lg-6'>
            <div className='icon-container'>
              {letter === 'A' && (
                <i style={{ fontSize: '10rem', color: 'green' }} className="fa-solid fa-a"></i>
              )}
              {letter === 'B' && (
                <i style={{ fontSize: '10rem', color: '#FFCC00' }} className="fa-solid fa-b"></i>
              )}
              {letter === 'C' && (
                <i style={{ fontSize: '10rem', color: 'red' }} className="fa-solid fa-c"></i>
              )}
            </div>
          </div>
        </div>
        {/* 2 */}
        <div style={{ width: '100%' }}>
          <div className='card-header border-0 py-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-3 mb-1'>Score de crédito manual</span>
              <span className='text-muted fw-semibold fs-7'>Resultados de entidad bancaria</span>
            </h3>
          </div>
          <div className="row g-5">
              <div className="card card-custom card-stretch-33 shadow mb-5" style={{backgroundColor : `${returnColorOnScore(score)}`}}>
                <div className="card-header">
                  <h1 className="card-title" style={{fontSize: '30px', fontWeight: 'bold'}}>{score}</h1>
                </div>
                <div className="card-body"  style={{fontSize: '15px'}}>
                  {returnTextOnScore(score)}
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const chartOptions = (chartColor: string, chartHeight: string): ApexOptions => {
  const baseColor = getCSSVariableValue('--kt-' + chartColor)
  const lightColor = getCSSVariableValue('--kt-' + chartColor + '-light')
  const labelColor = getCSSVariableValue('--kt-gray-700')

  return {
    series: [74],
    chart: {
      fontFamily: 'inherit',
      height: chartHeight,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: '65%',
        },
        dataLabels: {
          name: {
            show: false,
            fontWeight: '700',
          },
          value: {
            color: labelColor,
            fontSize: '30px',
            fontWeight: '700',
            offsetY: 12,
            show: true,
            formatter: function (val) {
              return val + '%'
            },
          },
        },
        track: {
          background: lightColor,
          strokeWidth: '100%',
        },
      },
    },
    colors: [baseColor],
    stroke: {
      lineCap: 'round',
    },
    labels: ['Progress'],
  }
}

export { RoundedChart }
