/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { PageLink, PageTitle } from './../../../_metronic/layout/core'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import Swal from 'sweetalert2'
import Tooltip from '@mui/material/Tooltip'
// --COMPONENT
import { TableLoader } from '../../components/TableLoader'
// --PERMISSIONS
import CheckInputPermission from '../../core/CheckInputPermission'
import { PermissionModel } from '../../core/_models'

import { GET_REQUEST_LIST_BUSINESS_REQUEST } from './core/_request'


const Breadcrumbs: Array<PageLink> = [
  {
    title: 'Credito Empresarial',
    path: 'request/credit/business',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]


const headers: Array<any> = [
  'Nombres',
  'RNC',
  'Persona Contacto',
  'Correo Electrónico',
  'Teléfono',
  'Celular',
  'Estado',
  'Acciones',
]


export default function BusinessCreditRequest() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [requestList, setrequestList] = useState<any[]>([])
  const [userPermission, setUserPermission] = useState<PermissionModel>()

  useEffect(() => {
    GET_REQUEST_LIST_BUSINESSL_REQUEST_FUNC()
    setUserPermission(CheckInputPermission('Credito Empresarial'))
  }, [])

  async function GET_REQUEST_LIST_BUSINESSL_REQUEST_FUNC() {
    setLoading(true)
    const { data: result } = await GET_REQUEST_LIST_BUSINESS_REQUEST();
    setrequestList(result.data)
    setLoading(false)
  }

  function CLASS_STATUS(currenStatus: string) {
    var color = ''
    switch (currenStatus) {
      case 'RQ001':
        color = 'badge fs-7 fw-semibold badge-light-warning'
        break
      case 'RQ002':
        color = 'badge fs-7 fw-semibold badge-light-primary'
        break
      case 'RQ003':
        color = 'badge fs-7 fw-semibold badge-light-success'
        break
      case 'RQ004':
        color = 'badge fs-7 fw-semibold badge-light-danger'
        break
      default:
        color = 'badge fs-7 fw-semibold badge-light-warning'
        break
    }
    return color
  }

  function CLASS_LABEL(currentStatus: string) {
    var label = ''
    switch (currentStatus) {
      case 'RQ001':
        label = 'PENDIENTE'
        break
      case 'RQ002':
        label = 'EN PROCESO'
        break
      case 'RQ003':
        label = 'APROBADO'
        break
      case 'RQ004':
        label = 'DECLINADO'
        break
      default:
        label = 'PENDIENTE'
        break
    }

    return label
  }

  function viewDetails(requestId: number) {
    navigate('/request/credit/details', {
      state: {
        requestId: requestId,
        type: '2',
      },
    })
  }


  return (
    <>
      <PageTitle breadcrumbs={Breadcrumbs}>Listado de Solicitudes Empresariales</PageTitle>
      <div className={`card ${'Table'}`}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-1 mb-1'>Solicitudes Empresariales</span>
            <span className='text-muted mt-1 fw-semibold fs-5'>
              Listado de Solicitudes empresariales via formulario
            </span>
          </h3>
        </div>

        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
                <tr>
                  {headers.map((data, index) => (
                    <>
                      <th key={index} className='min-w-150px text-muted '>
                        {data.toUpperCase()}
                      </th>
                    </>
                  ))}
                </tr>
              </thead>
              {loading && <TableLoader />}
              <tbody>
              {requestList.map((data, index) => (
                  <tr key={index}>
                    <td>
                      <a className='text-dark fw-bold text-hover-primary d-block fs-6'>
                        {data.names}
                      </a>
                    </td>
                    <td>
                      <a className='text-dark fw-bold text-hover-primary d-block fs-6'>
                        {data.rnc}
                      </a>
                    </td>
                    <td>
                      <a className='text-dark fw-bold text-hover-primary d-block fs-6'>
                        {data.contactPerson}
                      </a>
                    </td>
                    <td>
                      <a className='text-dark fw-bold text-hover-primary d-block fs-6'>
                        {data.email}
                      </a>
                    </td>
                    <td>
                      <a className='text-dark fw-bold text-hover-primary d-block fs-6'>
                        {data.tell}
                      </a>
                    </td>
                    <td>
                      <a className='text-dark fw-bold text-hover-primary d-block fs-6'>
                        {data.celular}
                      </a>
                    </td>
                    <td>
                      <a className='text-dark fw-bold text-hover-primary d-block fs-6'>
                        <span className={CLASS_STATUS(data.statusCode)}>
                          {CLASS_LABEL(data.statusCode)}
                        </span>
                      </a>
                    </td>
                    <td>
                      {userPermission?.get && (
                        <div className='d-flex  flex-shrink-0'>
                          <Tooltip title='Ver Detalle'>
                            <a
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                              onClick={() => viewDetails(data.id)}
                            >
                              <i className='bi bi-eye'></i>
                            </a>
                          </Tooltip>
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>  
            </table>
          </div>
        </div>
      </div>
    </>
  )
}
