/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'
import {
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  MixedWidget8,
  CardsWidget7,
  CardsWidget17,
  CardsWidget20,
  ListsWidget26,
  EngageWidget10,
} from '../../../_metronic/partials/widgets'

import { GET_DASHBOARD_RESUME_REQUEST } from './core/_request'

interface DashboardResume {
  pendings: number
  inProgress: number
  approved: number
  declined: number
  totalPersonalRequests: number
  totalBusinessRequest: number
}


type Props = {
  resume?: DashboardResume
}
const DashboardPage: FC<Props> = ({ resume }) => (

  <>
    <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
          <CardsWidget20
            className='h-md-100 mb-5 mb-xl-10'
            description='Total Solic. Personales'
            color='#023b64'
            total={resume?.totalPersonalRequests}
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
        </div>
        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
          <CardsWidget20
            className='h-md-100 mb-5 mb-xl-10'
            description='Total Solic. Empresariales'
            color='#023b64'
            total={resume?.totalBusinessRequest}
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
        </div>

    </div>

    <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
      <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
        <CardsWidget20
          className='h-md-100 mb-5 mb-xl-10'
          description='Solic. Pendientes'
          color='#F7A917'
          total={resume?.pendings}
          img={toAbsoluteUrl('/media/patterns/vector-1.png')}
        />
        {/* <CardsWidget7
          className='h-md-100 mb-5 mb-xl-10'
          description='Professionals'
          icon={false}
          stats={357}
          labelColor='dark'
          textColor='gray-300'
        /> */}
      </div>
      <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
        <CardsWidget20
          className='h-md-100 mb-5 mb-xl-10'
          description='Solic. en Progreso'
          color='#3498DB'
          total={resume?.inProgress}
          img={toAbsoluteUrl('/media/patterns/vector-1.png')}
        />
        {/* <CardsWidget7
          className='h-md-100 mb-5 mb-xl-10'
          description='Professionals'
          icon={false}
          stats={357}
          labelColor='dark'
          textColor='gray-300'
        /> */}
      </div>
      <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
        <CardsWidget20
          className='h-md-100 mb-5 mb-xl-10'
          description='Solic. Aprobadas'
          color='#28B463'
          total={resume?.approved}
          img={toAbsoluteUrl('/media/patterns/vector-1.png')}
        />
        {/* <CardsWidget7
          className='h-md-100 mb-5 mb-xl-10'
          description='Professionals'
          icon={false}
          stats={357}
          labelColor='dark'
          textColor='gray-300'
        /> */}
      </div>
      <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
        <CardsWidget20
          className='h-md-100 mb-5 mb-xl-10'
          description='Solic. Declinadas'
          color='#C0392B'
          total={resume?.declined}
          img={toAbsoluteUrl('/media/patterns/vector-1.png')}
        />
        {/* <CardsWidget7
          className='h-md-100 mb-5 mb-xl-10'
          description='Professionals'
          icon={false}
          stats={357}
          labelColor='dark'
          textColor='gray-300'
        /> */}
      </div>

      {/* <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
        <CardsWidget17 className='h-md-100 mb-5 mb-xl-10' />
        <ListsWidget26 className='h-lg-50' />
      </div> */}
    </div>
  </>
)

// interface DashboardResume{
//   Pendings : number
//   InProgress : number
//   Approved : number
//   Declined : number
//   TotalPersonalRequests : number
//   TotalBusinessRequest : number
// }

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [resume, setResume] = useState<DashboardResume>({
    approved: 0,
    declined: 0,
    inProgress: 0,
    pendings: 0,
    totalBusinessRequest: 0,
    totalPersonalRequests: 0
  })
  useEffect(() => {
    GET_DASHBOARD_RESUME_REQUEST_FUNC()
  }, [])

  async function GET_DASHBOARD_RESUME_REQUEST_FUNC() {
    setLoading(true)
    const { data: result } = await GET_DASHBOARD_RESUME_REQUEST()
    console.log("result", result);

    setResume(result.data)
    setLoading(false)
  }
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <DashboardPage resume={resume} />
    </>
  )
}

export { DashboardWrapper }
