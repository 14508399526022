/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useRef, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {PageLink, PageTitle} from './../../../_metronic/layout/core'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import Swal from 'sweetalert2'
import Tooltip from '@mui/material/Tooltip'
// --COMPONENT
import {TableLoader} from '../../components/TableLoader'
// --PERMISSIONS
import CheckInputPermission from '../../core/CheckInputPermission'
import {PermissionModel} from '../../core/_models'

import {GET_REQUEST_LIST_PERSONAL_REQUEST} from './core/_request'

const Breadcrumbs: Array<PageLink> = [
  {
    title: 'Usuarios',
    path: 'security/users',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const headers: Array<any> = [
  'Nombres',
  'Apellidos',
  'Correo Electrónico',
  'Celular',
  'Tipo',
  'Estado',
  'Acciones',
]

export default function PersonalCreditRequest() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [requestList, setrequestList] = useState<any[]>([])
  const [userPermission, setUserPermission] = useState<PermissionModel>()

  useEffect(() => {
    GET_REQUEST_LIST_PERSONAL_REQUEST_FUNC()
    setUserPermission(CheckInputPermission('Credito Personal'))
  }, [])

  async function GET_REQUEST_LIST_PERSONAL_REQUEST_FUNC() {
    setLoading(true)
    const {data: result} = await GET_REQUEST_LIST_PERSONAL_REQUEST();
    setrequestList(result.data)
    setLoading(false)
  }

  function CLASS_STATUS(currenStatus: string) {
    var color = ''
    switch (currenStatus) {
      case 'RQ001':
        color = 'badge fs-7 fw-semibold badge-light-warning'
        break
      case 'RQ002':
        color = 'badge fs-7 fw-semibold badge-light-primary'
        break
      case 'RQ003':
        color = 'badge fs-7 fw-semibold badge-light-success'
        break
      case 'RQ004':
        color = 'badge fs-7 fw-semibold badge-light-danger'
        break
      default:
        color = 'badge fs-7 fw-semibold badge-light-warning'
        break
    }
    return color
  }

  function REQUEST_TYPE(request: string) {
    var label = ''
    switch (request) {
      case 'RT001':
        label = 'PERSONAL'
        break
      case 'RT002':
        label = 'EMPRESARIAL'
        break
      default:
        label = 'PERSONAL'
        break
    }

    return label
  }

  function CLASS_LABEL(currentStatus: string) {
    var label = ''
    switch (currentStatus) {
      case 'RQ001':
        label = 'PENDIENTE'
        break
      case 'RQ002':
        label = 'EN PROCESO'
        break
      case 'RQ003':
        label = 'APROBADO'
        break
      case 'RQ004':
        label = 'DECLINADO'
        break
      default:
        label = 'PENDIENTE'
        break
    }

    return label
  }

  function viewDetails(requestId: number) {
    navigate('/request/credit/details', {
      state: {
        requestId: requestId,
        type: '1',
      },
    })
  }

  return (
    <>
      <PageTitle breadcrumbs={Breadcrumbs}>Listado de Solicitudes Personales</PageTitle>
      <div className={`card ${'Table'}`}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-1 mb-1'>Solicitudes Personales</span>
            <span className='text-muted mt-1 fw-semibold fs-5'>
              Listado de Solicitudes personales via formulario
            </span>
          </h3>
          {/* <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Agregar'
            // onClick={() => openModalFunc()}
          >
            <a className='btn btn-sm btn-light-primary'>
              <i className='bi bi-plus'></i>
              Agregar Nuevo
            </a>
          </div> */}
        </div>

        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
                <tr>
                  {headers.map((data, index) => (
                    <>
                      <th key={index} className='min-w-150px text-muted '>
                        {data.toUpperCase()}
                      </th>
                    </>
                  ))}
                </tr>
              </thead>
              {loading && <TableLoader />}
              <tbody>
                {requestList.map((data, index) => (
                  <tr key={index}>
                    <td>
                      <a className='text-dark fw-bold text-hover-primary d-block fs-6'>
                        {data.names}
                      </a>
                    </td>
                    <td>
                      <a className='text-dark fw-bold text-hover-primary d-block fs-6'>
                        {data.lastNames}
                      </a>
                    </td>
                    <td>
                      <a className='text-dark fw-bold text-hover-primary d-block fs-6'>
                        {data.email}
                      </a>
                    </td>
                    <td>
                      <a className='text-dark fw-bold text-hover-primary d-block fs-6'>
                        {data.celular}
                      </a>
                    </td>
                    
                    <td>
                      <a className='text-dark fw-bold text-hover-primary d-block fs-6'>
                        <span>
                          {REQUEST_TYPE(data.requestType)}
                        </span>
                      </a>
                    </td>
                    <td>
                      <a className='text-dark fw-bold text-hover-primary d-block fs-6'>
                        <span className={CLASS_STATUS(data.statusCode)}>
                          {CLASS_LABEL(data.statusCode)}
                        </span>
                      </a>
                    </td>
                    <td>
                      {userPermission?.get && (
                        <div className='d-flex  flex-shrink-0'>
                          <Tooltip title='Ver Detalle'>
                            <a
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                              onClick={() => viewDetails(data.id)}
                            >
                              <i className='bi bi-eye'></i>
                            </a>
                          </Tooltip>
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}
