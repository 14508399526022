/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { PageLink, PageTitle } from './../../../_metronic/layout/core'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import Swal from 'sweetalert2'
// --ADD MODAL & COMPONENT
import AddCreditLevel from '../../components/modal/AddCreditLevel'
import { TableLoader } from '../../components/TableLoader'
import Tooltip from '@mui/material/Tooltip'
// --PERMISSIONS
import CheckInputPermission from '../../core/CheckInputPermission'
import { PermissionModel } from '../../core/_models'
// --ENDPOINTS
import {
  GET_CREDIT_LEVEL_LIST_REQUEST,
  UPDATE_CREDIT_LEVEL_REQUEST,
  DELETE_CREDIT_LEVEL_REQUEST,
  CREATE_CREDIT_LEVEL_REQUEST,
} from '../credit-levels-manager/core/_request'

const RolesBreadcrumbs: Array<PageLink> = [
  {
    title: 'Roles',
    path: 'security/roles',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const headers: Array<any> = ['Letra', 'Desde', 'Hasta', 'Acciones']

let initValues = {
  id: 0,
  letter: "",
  from: 0,
  to: 0
}

const CreditLevelPage = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [currentRow, setCurrentRow] = useState(initValues)
  const [userPermission, setUserPermission] = useState<PermissionModel>()
  const [creditList, setCreditList] = useState<any[]>([])

  useEffect(() => {
    GET_CREDIT_LEVEL_LIST_FUNC()
    setUserPermission(CheckInputPermission('Seguridad'))
  }, [])

  async function GET_CREDIT_LEVEL_LIST_FUNC() {
    setLoading(true)
    const { data: result } = await GET_CREDIT_LEVEL_LIST_REQUEST()
    setCreditList(result.data)
    setLoading(false)
  }

  async function DELETE_CREDIT_LEVEL_REQUEST_FUNC(creditLevelId: string) {
    Swal.fire({
      title: 'Información!',
      text: '¿Esta seguro/a de eliminar este registro?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Eliminar',
    }).then(async (result) => {
      if (result.value) {
        if (result.value) {
          setLoading(true)
          try {
            const { data: result } = await DELETE_CREDIT_LEVEL_REQUEST(creditLevelId)
            if (result?.isSuccess) {
              Swal.fire({
                title: 'Enhorabuena!',
                text: 'El registro ha sido eliminado exitosamente',
                icon: 'success',
              })
              setLoading(false)
              GET_CREDIT_LEVEL_LIST_FUNC()
            }
          } catch (error) {
            setLoading(false)
            Swal.fire({
              title: 'Información',
              text: 'Algo ha salido mal. Contactar soporte técnico',
              icon: 'info',
            })
          }
        }
      }
    })
  }

  function EditOnModal(object: any) {
    setCurrentRow(object)
    setOpenModal(true)
  }

  function openModalFunc() {
    setOpenModal(true)
  }

  function handleCloseModal() {
    GET_CREDIT_LEVEL_LIST_FUNC()
    setOpenModal(false)
  }

  return (
    <>
      <PageTitle breadcrumbs={RolesBreadcrumbs}>Niveles de Crédito</PageTitle>
      <div className={`card ${'Table'}`}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-1 mb-1'>Niveles de Créditos</span>
            <span className='text-muted mt-1 fw-semibold fs-5'>Listado de niveles crediticios</span>
          </h3>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Agregar'
            onClick={() => openModalFunc()}
          >
            <a className='btn btn-sm btn-light-primary'>
              <i className='bi bi-plus'></i>
              Agregar Nuevo
            </a>
          </div>
        </div>
      </div>

      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr>
                {headers.map((data, index) => (
                  <>
                    <th key={index} className='min-w-150px'>
                      {data}
                    </th>
                  </>
                ))}
              </tr>
            </thead>
            {loading && <TableLoader />}
            <tbody>
              {creditList.map((data, index) => (
                <tr key={index}>
                  <td>
                    <a className='text-dark fw-bold text-hover-primary d-block fs-6'>
                      {data.letter}
                    </a>
                  </td>
                  <td>
                    <a className='text-dark fw-bold text-hover-primary d-block fs-6'>
                      {data.from}
                    </a>
                  </td>
                  <td>
                    <a className='text-dark fw-bold text-hover-primary d-block fs-6'>
                      {data.to}
                    </a>
                  </td>
                  <td>
                    <div className='d-flex  flex-shrink-0'>
                      {userPermission?.update && (
                        <Tooltip title='Editar'>
                          <a
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            onClick={() => EditOnModal(data)}
                          >
                            <KTSVG
                              path='/media/icons/duotune/art/art005.svg'
                              className='svg-icon-3'
                            />
                          </a>
                        </Tooltip>
                      )}

                      {userPermission?.delete && (
                        <Tooltip title='Eliminar'>
                          <a
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                            onClick={() => DELETE_CREDIT_LEVEL_REQUEST_FUNC(data.id)}
                          >
                            <KTSVG
                              path='/media/icons/duotune/general/gen027.svg'
                              className='svg-icon-3'
                            />
                          </a>
                        </Tooltip>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <AddCreditLevel
        show={openModal}
        valuesEdit={currentRow}
        handleClose={() => handleCloseModal()}
      />
    </>
  )
}

export default CreditLevelPage
