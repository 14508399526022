/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { PageLink, PageTitle } from './../../../_metronic/layout/core'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import Swal from 'sweetalert2'
import Tooltip from '@mui/material/Tooltip'
// --COMPONENT
import { TableLoader } from '../../components/TableLoader'
import AdvanceFilterToolbar from '../../components/creditRequest/AdvanceFilterToolbar';
import ExportApprovedRequest from '../../components/excel-export/ExportApprovedRequest'
// --PERMISSIONS
import CheckInputPermission from '../../core/CheckInputPermission'
import { PermissionModel } from '../../core/_models'

import { GET_REQUEST_APPROVED_LIST } from './core/_request'

const Breadcrumbs: Array<PageLink> = [
    {
        title: 'Usuarios',
        path: 'security/users',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]


const headers: Array<any> = [
    // '',
    'Código SIGCI',
    'Nombres',
    'Apellidos',
    'Correo Electrónico',
    // 'Celular',
    'Tipo',
    'Acciones',
]

let initialValues = {
    startDate: new Date(),
    endDate: new Date(),
    email: '',
    IdentificationNo: '',
    requestType: '',
    score: 0,
    sigCode: '',
    hasDateRange: false
}

export default function ApprovedRequests() {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [requestList, setrequestList] = useState<any[]>([])
    const [requestListCopy, setrequestListCopy] = useState<any[]>([])
    const [userPermission, setUserPermission] = useState<PermissionModel>()
    const [showAdvancedFilter, setShowAdvancedFilter] = useState(false);

    useEffect(() => {
        GET_REQUEST_LIST_PERSONAL_REQUEST_FUNC(initialValues)
        setUserPermission(CheckInputPermission('Credito Personal'))
    }, [])

    async function GET_REQUEST_LIST_PERSONAL_REQUEST_FUNC(object: typeof initialValues) {
        setLoading(true)
        const { data: result } = await GET_REQUEST_APPROVED_LIST(object)
        setrequestList(result.data)
        setrequestListCopy(result.data)
        setLoading(false)
    }

    function REQUEST_TYPE(request: string) {
        var label = ''
        switch (request) {
            case 'RT001':
                label = 'PERSONAL'
                break
            case 'RT002':
                label = 'EMPRESARIAL'
                break
            default:
                label = 'PERSONAL'
                break
        }

        return label
    }

    function viewDetails(requestId: number) {
        navigate('/request/credit/details', {
            state: {
                requestId: requestId,
                type: '1',
            },
        })
    }

    function SearchByName(value: string) {
        if (value.length > 0) {
            var result = requestList.filter(x => x.names?.toLowerCase().includes(value.toLowerCase()))
            setrequestList(result);
        } else {
            setrequestList(requestListCopy);
        }
    }

    return (
        <>
            <PageTitle breadcrumbs={Breadcrumbs}>Listado de Solicitudes Aprobadas</PageTitle>
            <div className={`card ${'Table'}`}>
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bold fs-1 mb-1'>Solicitudes Aprobadas</span>
                        <span className='text-muted mt-1 fw-semibold fs-5'>
                            Reporte de Clientes
                        </span>
                    </h3>
                </div>
                <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base' style={{ padding: '10px' }}>
                    <div className='d-flex align-items-center position-relative my-1' style={{ marginRight: '10px' }}>
                        <KTSVG
                            path='/media/icons/duotune/general/gen021.svg'
                            className='svg-icon-1 position-absolute ms-6'
                        />
                        <input
                            type='text'
                            data-kt-user-table-filter='search'
                            className='form-control form-control-solid w-250px ps-14'
                            placeholder='Nombre...'
                            // value={searchTerm}
                            onChange={(e) => SearchByName(e.target.value)}
                        />
                    </div>
                    <button
                        // disabled={isLoading}
                        onClick={() => setShowAdvancedFilter(!showAdvancedFilter)}
                        type='button'
                        className='btn btn-light-primary me-3'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                    >
                        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
                        Mas Filtros
                    </button>
                    <ExportApprovedRequest values={requestList}/>
                </div>
                {
                    showAdvancedFilter && (
                        <div style={{ marginTop: '10px' }} className="fade-in-custom " >
                            <AdvanceFilterToolbar makeRequest={object => GET_REQUEST_LIST_PERSONAL_REQUEST_FUNC(object)} />
                        </div>
                    )

                }

                <div className='card-body py-3'>
                    <div className='table-responsive'>
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                            <thead>
                                <tr>
                                    {headers.map((data, index) => (
                                        <>
                                            <th key={index} className='min-w-150px text-muted '>
                                                {data.toUpperCase()}
                                            </th>
                                        </>
                                    ))}
                                </tr>
                            </thead>
                            {loading && <TableLoader />}
                            <tbody>
                                {requestList.map((data, index) => (
                                    <tr key={index}>
                                        {/* <th className='w-25px'>
                                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                                <input
                                                    className='form-check-input'
                                                    type='checkbox'
                                                    value='1'
                                                    data-kt-check='true'
                                                    data-kt-check-target='.widget-9-check'
                                                />
                                            </div>
                                        </th> */}
                                        <td>
                                            <a className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                                {data.sigCode}
                                            </a>
                                        </td>
                                        <td>
                                            <a className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                                {data.names}
                                            </a>
                                        </td>
                                        <td>
                                            <a className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                                {data.lastNames}
                                            </a>
                                        </td>
                                        <td>
                                            <a className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                                {data.email}
                                            </a>
                                        </td>
                                        {/* <td>
                                            <a className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                                {data.celular}
                                            </a>
                                        </td> */}

                                        <td>
                                            <a className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                                <span>
                                                    {REQUEST_TYPE(data.requestType)}
                                                </span>
                                            </a>
                                        </td>
                                        <td>
                                            {userPermission?.get && (
                                                <div className='d-flex  flex-shrink-0'>
                                                    <Tooltip title='Ver Detalle'>
                                                        <a
                                                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                            onClick={() => viewDetails(data.id)}
                                                        >
                                                            <i className='bi bi-eye'></i>
                                                        </a>
                                                    </Tooltip>
                                                </div>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )

}