/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { createPortal } from 'react-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../../_metronic/helpers'
import Swal from 'sweetalert2'

const rolePermissionSchema = Yup.object().shape({
    statusId: Yup.string().required('El estado es requerido'),
})

type Props = {
    show: boolean
    updateRequest: (name: string) => void
    // statusId: number
    //   hasSaved: () => boolean
    handleClose: () => void
}

const initialValues = {
    statusId: ''
}

const StatusArray = [
    { label: "PENDIENTE", value: "RQ001" },
    { label: "EN PROCESO", value: "RQ002" },
    { label: "APROBADO", value: "RQ003" },
    { label: "DECLINADO", value: "RQ004" }
]

const modalsRoot = document.getElementById('root-modals') || document.body

const ChangeReqeustStatusModal = ({ show, handleClose, updateRequest }: Props) => {
    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        initialValues,
        validationSchema: rolePermissionSchema,
        onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
            console.log('values', values)
            updateRequest(values.statusId)
            handleClose()
            resetForm()

        },
    })


    return createPortal(
        <Modal
            id='change_status'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-400px'
            show={show}
            onHide={handleClose}
            //   onEntered={loadStepper}
            backdrop={true}
        >
            <div className='modal-header'>
                <h2>Seleccionar Estado</h2>
                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>
            <div className='modal-body py-lg-10 px-lg-10'>
                <form className='form w-100' onSubmit={formik.handleSubmit} id='kt_login_signin_form'>
                    <div className='d-grid mb-10'>
                        <label className='col-lg-4 col-form-label required fw-bold fs-6'>Estado</label>

                        <div className='col-lg-8 fv-row'>
                            <select
                                className='form-select form-select-solid form-select-lg'
                                {...formik.getFieldProps('statusId')}
                            >
                                <option value=''>Estado..</option>
                                {StatusArray.map((data, index) => (
                                    <option key={index} value={data.value}>
                                        {data.label}
                                    </option>
                                ))}
                            </select>
                            {formik.touched.statusId && formik.errors.statusId && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>{formik.errors.statusId}</div>
                                </div>
                            )}
                        </div>
                    </div>
                    <button
                        type='submit'
                        id='kt_sign_in_submit'
                        className='btn btn-primary'
                        disabled={loading}
                    >
                        {!loading && <span className='indicator-label'>Guardar</span>}
                        {loading && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                                Espere...
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                </form>
            </div>
        </Modal>,
        modalsRoot
    )
}

export default ChangeReqeustStatusModal