import axios from 'axios'
import {AuthModel} from '../../app/modules/auth/core/_models'

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'

const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

// --API ROUTE
const API_URL = process.env.REACT_APP_API_URL

const instance = axios.create({
  baseURL: API_URL,
  //   withCredentials: true,
  timeout: 30000,
  headers: {
    ContentType: 'application/json',
    Accept: '*',
  },
})

instance.interceptors.request.use(
  function (response: any) {
    const auth = getAuth()
    if (auth && auth.api_token) {
      response.headers.Authorization = `Bearer ${auth.api_token}`
    }
    return response
  },
  function (error) {
    if (error.response.status === 500) {
      console.log(error)
    }

    return Promise.reject(error)
  }
)

export default instance
