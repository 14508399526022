/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'
import {KTSVG} from '../../_metronic/helpers'
import {Card4} from '../../_metronic/partials/content/cards/Card4'
import Swal from 'sweetalert2'
// ---COMPONENTS
import {UploadFilesModal} from './UploadFilesModal'
// ---REQUEST
import {
  CREATE_FILES_REQUEST,
  GET_REQUEST_FILES_REQUEST,
} from '../pages/credit-request-manager/core/_request'
import moment from 'moment'
import {PermissionModel} from '../core/_models'

type Props = {
  requestType: string
  requestId: number
  updateRequest: () => void
  loading: boolean
  permissions: PermissionModel | undefined
}

export default function FileList({
  requestType,
  requestId,
  loading,
  updateRequest,
  permissions,
}: Props) {
  const [fileModal, setFileModal] = useState(false)
  const [loadings, setLoadings] = useState(false)
  const [fileList, setFileList] = useState<any[]>([])

  useEffect(() => {
    GET_REQUEST_FILES_REQUEST_FUNC(requestId, requestType)
  }, [requestId, requestType])

  async function GET_REQUEST_FILES_REQUEST_FUNC(id: number, type: string) {
    setLoadings(true)
    const {data: result} = await GET_REQUEST_FILES_REQUEST(id, type)
    setFileList(result.data)
    setLoadings(false)
  }

  function handleClose(array: any, value: boolean) {
    if (value) {
      array.forEach((fileObject: {Type: string; RequestId: number; Extention: string}) => {
        fileObject.Type = requestType
        fileObject.RequestId = requestId
        fileObject.Extention = 'xls'
      })
      console.log('array', array)
      CREATE_FILES_REQUEST_FUNC(array)
      setFileModal(false)
    } else {
    }
    setFileModal(false)
  }

  async function CREATE_FILES_REQUEST_FUNC(array: any) {
    try {
      setLoadings(true)
      const {data: result} = await CREATE_FILES_REQUEST(array)
      if (result?.isSuccess) {
        Swal.fire({
          title: 'Enhorabuena!',
          text: 'Los archivos se han subido de correctamente',
          icon: 'success',
        })
        GET_REQUEST_FILES_REQUEST_FUNC(requestId, requestType)
      } else {
        Swal.fire({
          title: 'Información',
          text: 'Algo ha salido mal. Contactar soporte técnico',
          icon: 'info',
        })
      }
      setLoadings(false)
    } catch (error) {
      Swal.fire({
        title: 'Información',
        text: 'Algo ha salido mal. Contactar soporte técnico',
        icon: 'info',
      })
    }
  }

  return (
    <>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-2'>
          Documentación
          <span className='fs-6 text-gray-400 fw-bold ms-1'>
            Listado de documentos adjuntados al perfil
          </span>
        </h3>

        <div className='d-flex my-2'>
          <div className='d-flex align-items-center position-relative me-4'>
            <KTSVG
              path='/media/icons/duotune/general/gen021.svg'
              className='svg-icon-3 position-absolute ms-3'
            />
            <input
              type='text'
              id='kt_filter_search'
              className='form-control form-control-white form-control-sm w-150px ps-9'
              placeholder='Buscar...'
            />
          </div>

          {permissions?.add && (
            <a className='btn btn-primary btn-sm' onClick={() => setFileModal(true)}>
              Subir Nuevo
            </a>
          )}
        </div>
      </div>
      <div>
        <div className='row g-6 g-xl-9 mb-6 mb-xl-9'>
          {fileList.map((data, index) => (
            <div key={index} className='col-12 col-sm-12 col-xl'>
              <Card4
                icon='/media/icons/duotune/files/fil008.svg'
                title={data.route}
                description={moment(data.createdAt).format('L')}
              />
            </div>
          ))}
        </div>
      </div>
      <UploadFilesModal
        show={fileModal}
        handleClose={(array, value) => handleClose(array, value)}
      />
    </>
  )
}
