/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { KTSVG } from '../../../_metronic/helpers'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import Tooltip from '@mui/material/Tooltip'
import Swal from 'sweetalert2'

const Schema = Yup.object().shape({
    sigCode: Yup.string().nullable(),
    score: Yup.string().nullable(),
    requestType: Yup.string().nullable(),
    IdentificationNo: Yup.string().nullable(),
})

let initValues = {
    startDate: new Date(),
    endDate: new Date(),
    email: '',
    IdentificationNo: '',
    requestType: '',
    score: 0,
    sigCode: '',
    hasDateRange: false
}

type Props = {
    makeRequest: (object: typeof initValues) => void
}

const requestType = [
    { label: 'Personal', value: 'RT001' },
    { label: 'Empresarial', value: 'RT002' },
]


export default function AdvanceFilterToolbar({ makeRequest }: Props) {
    const [initialValues, setInitialValues] = useState(initValues)

    useEffect(() => {
    }, [])

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
            setSubmitting(true)
            makeRequest(values)
            setSubmitting(false)
        },
    })

    function clearForm() {
        formik.resetForm()
        makeRequest(initialValues)
    }

    return (
        <>
            <form onSubmit={formik.handleSubmit} id='form_filter'>
                <div className='d-flex' data-kt-user-table-toolbar='base' style={{ padding: '10px' }}>
                    <div className='d-flex align-items-center position-relative my-1' style={{ marginRight: '10px' }}>
                        <input
                            className='form-control form-control-solid w-250px ps-14'
                            placeholder='Código SIGCI'
                            {...formik.getFieldProps('sigCode')}
                            type='text'
                            autoComplete='off'
                        />
                    </div>
                    <div className='d-flex align-items-center position-relative my-1' style={{ marginRight: '10px' }}>
                        <input

                            className='form-control form-control-solid w-250px ps-14'
                            placeholder='No. Identificación'
                            {...formik.getFieldProps('identificationNo')}
                            type='text'
                            autoComplete='off'
                        />
                    </div>
                    <div className='d-flex align-items-center position-relative my-1' style={{ marginRight: '10px' }}>
                        <input

                            className='form-control form-control-solid w-250px ps-14'
                            placeholder='Correo Electrónico'
                            {...formik.getFieldProps('email')}
                            type='text'
                            autoComplete='off'
                        />
                    </div>
                    <div className='d-flex align-items-center position-relative my-1' style={{ marginRight: '10px' }}>
                        <input

                            // className='form-control form-control-solid w-250px ps-14'
                            placeholder='Score >='
                            {...formik.getFieldProps('score')}
                            type='text'
                            autoComplete='off'
                            className={clsx(
                                'form-control form-control-solid w-250px ps-14',
                                {
                                    'is-invalid': formik.touched.score && formik.errors.score,
                                },
                                {
                                    'is-valid': formik.touched.score && !formik.errors.score,
                                }
                            )}
                        />
                    </div>
                </div>
                <div className='d-flex' data-kt-user-table-toolbar='base' style={{ padding: '10px' }}>
                    <div className='d-flex align-items-center position-relative my-1' style={{ marginRight: '10px', width: '19rem' }}>
                        <select
                            className='form-select form-select-solid form-select-lg'
                            {...formik.getFieldProps('requestType')}
                        >
                            <option value=''>Tipo</option>
                            {requestType.map((data, index) => (
                                <option key={index} value={data.value}>
                                    {data.label}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className='d-flex' data-kt-user-table-toolbar='base' style={{ padding: '10px' }}>
                    <button type='submit' className='btn btn-primary' style={{ marginRight: '10px' }}>
                        <KTSVG path='/media/icons/duotune/general/gen021.svg' className='svg-icon-2' />
                        Buscar
                    </button>
                    <button type='button' className='btn btn-light-primary me-3' onClick={() => clearForm()}>
                        <KTSVG path='/media/icons/duotune/general/gen034.svg' className='svg-icon-2' />
                        Limpiar Filtros
                    </button>
                </div>
            </form>
        </>
    )
}
