/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useRef, useEffect, useCallback} from 'react'
import clsx from 'clsx'
import {createPortal} from 'react-dom'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../_metronic/helpers'
import Swal from 'sweetalert2'
import {useDropzone} from 'react-dropzone'
// --REQUEST
import {UPLOAD_FILES_REQUEST} from '../pages/credit-request-manager/core/_request'

type Props = {
  show: boolean
  handleClose: (files: any, value: boolean) => void
}
const modalsRoot = document.getElementById('root-modals') || document.body

const UploadFilesModal = ({show, handleClose}: Props) => {
  const [loading, setLoading] = useState(false)
  const [fileList, setFileList] = useState<any[]>([])

  const onDrop = useCallback((acceptedFiles: any) => {
    acceptedFiles.forEach((element: any) => {
      fileList.push(element)
    })
  }, [])
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  function closingModal() {
    console.log('fileList', fileList)
    handleClose([], false)
  }

  async function UPLOAD_FILES_REQUEST_FUNC() {
    try {
      const formData = new FormData()
      fileList.forEach((file) => {
        formData.append('files', file)
      })
      const {data: result} = await UPLOAD_FILES_REQUEST(formData)
      console.log('result', result)
      if (result?.isSuccess) {
        setLoading(false)
        setFileList([])
        handleClose(result.data, true)
      } else {
        Swal.fire({
          title: 'Información',
          text: 'Algo ha salido mal. Contactar soporte técnico',
          icon: 'info',
        })
      }
    } catch (error) {
      console.log('error', error)

      Swal.fire({
        title: 'Información',
        text: 'Algo ha salido mal. Contactar soporte técnico',
        icon: 'info',
      })
    }
  }

  return createPortal(
    <Modal
      id='upload_file'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-600px'
      show={show}
      onHide={() => closingModal()}
      backdrop={true}
    >
      <div className='modal-header'>
        <h2>Subir archivos</h2>
        <div
          className='btn btn-sm btn-icon btn-active-color-primary'
          onClick={() => closingModal()}
        >
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
      </div>
      <div className='modal-body py-lg-10 px-lg-10'>
        <div className='dropzone'>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Suelta los archivos aquí ...</p>
            ) : (
              <p>Arrastre y suelte algunos archivos aquí, o haga clic para seleccionar archivos</p>
            )}
          </div>
        </div>
      </div>
      <h2 style={{padding: '10px'}}>Adjuntados</h2>
      <div className='fileContainer'>
        <ul>
          {fileList.map((data, index) => (
            <li key={index}>{data.name}</li>
          ))}
        </ul>
      </div>

      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='upload_file'
          className='btn btn-primary'
          disabled={fileList.length === 0}
          onClick={() => UPLOAD_FILES_REQUEST_FUNC()}
        >
          {!loading && <span className='indicator-label'>Continuar</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Espere...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </Modal>,
    modalsRoot
  )
}

export {UploadFilesModal}
