import axios from '../api/api'
import {PermissionModel} from './_models'

// --API ROUTE
const API_URL = process.env.REACT_APP_API_URL

export const GET_PERMISSION_BYROLE = `${API_URL}/api/Page/GetPageRolesByIdRole?idRole=`

export function GET_ROLE_PERMISSION_BYROLE(idRole: number) {
  return axios.get(GET_PERMISSION_BYROLE + `${idRole}`)
}
