import axios from '../../../api/api'

// --API ROUTE
const API_URL = process.env.REACT_APP_API_URL

// --ENDPOINTS ROUTES -personal

export const GET_DASHBOARD_RESUME_URL = `${API_URL}/api/Request/GetRequestsDashboardResume`

//ACTUAL REQUEST - PERSONAL
export function GET_DASHBOARD_RESUME_REQUEST() {
    return axios.get(GET_DASHBOARD_RESUME_URL)
  }