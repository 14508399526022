import axios from '../../../api/api'

// --API ROUTE
const API_URL = process.env.REACT_APP_API_URL

// --ENDPOINTS ROUTES -personal
export const GET_REQUEST_LIST_PERSONAL = `${API_URL}/api/Request/GetRequestList`
export const GET_REQUEST_APPROVED = `${API_URL}/api/Request/GetApprovedRequestList`
export const GET_PERSONAL_REQUEST_BY_ID = `${API_URL}/api/Request/GetRequestListById?id=`
export const UPDATE_CREATE_ACTIVITY = `${API_URL}/api/Request/ChangeCreditRequestStatus`
export const UPDATE_REQUEST_SCORE_URL = `${API_URL}/api/Request/ChangeRequestScore`
export const GET_CREDIT_ACTIVITIES = `${API_URL}/api/Request/GetCreditRequestActivities?requestId=`
export const UPLOAD_FILES = `${API_URL}/api/Request/UploadRequestFiles`
export const CREATE_FILES = `${API_URL}/api/Request/CreateCreditRequestFiles`
export const GET_REQUEST_FILES = `${API_URL}/api/Request/GetCreditRequestFiles?`
export const GET_REQUEST_CREDIT_LEVEL = `${API_URL}/api/CreditLevel/GetRange/`

// --ENDPOINTS ROUTES -Business
// BUSINESS
export const GET_REQUEST_LIST_BUSINESS = `${API_URL}/api/Request/GetBusinessRequestList`
export const GET_BUSINESS_REQUEST_BY_ID = `${API_URL}/api/Request/GetBusinesRequestById?id=`

//ACTUAL REQUEST - PERSONAL
export function GET_REQUEST_LIST_PERSONAL_REQUEST() {
  return axios.get(GET_REQUEST_LIST_PERSONAL)
}
export function GET_REQUEST_APPROVED_LIST(request: any) {
  return axios.post(GET_REQUEST_APPROVED, request)
}

export function GET_PERSONAL_REQUEST_BY_ID_REQUEST(id: number) {
  return axios.get(GET_PERSONAL_REQUEST_BY_ID + `${id}`)
}

export function UPDATE_CREATE_ACTIVITY_REQUEST(request: any) {
  return axios.put(UPDATE_CREATE_ACTIVITY, request)
}

export function GET_CREDIT_ACTIVITIES_REQUEST(id: number) {
  return axios.get(GET_CREDIT_ACTIVITIES + `${id}`)
}

export function CREATE_FILES_REQUEST(request: any) {
  return axios.post(CREATE_FILES, request)
}

export function GET_REQUEST_FILES_REQUEST(id: number, type: string) {
  return axios.get(GET_REQUEST_FILES + `requestId=${id}&type=${type}`)
}

export function UPDATE_REQUEST_SCORE(request: any) {
  return axios.put(UPDATE_REQUEST_SCORE_URL, request)
}



//ACTUAL REQUEST - BUSINESS
export function GET_REQUEST_LIST_BUSINESS_REQUEST() {
  return axios.get(GET_REQUEST_LIST_BUSINESS)
}

export function GET_BUSINESS_REQUEST_BY_ID_REQUEST(id: number) {
  return axios.get(GET_BUSINESS_REQUEST_BY_ID + `${id}`)
}


// GENERIC 
export function GET_REQUEST_CREDIT_LEVEL_REQUEST(amount: number) {
  return axios.get(GET_REQUEST_CREDIT_LEVEL + `${amount}`)
}

export function UPLOAD_FILES_REQUEST(request: any) {
  console.log('request', request)

  return axios.post(UPLOAD_FILES, request, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

